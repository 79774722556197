import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IResponse } from 'src/app/interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public login(email: string, password: string) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return this.http.post<IResponse>(`${environment.apiUrl}/account/login`, formData)
      .pipe(map((response: IResponse) => {
        if (response.success) {
          this.authService.sessionChecked = true;
          this.authService.isLoggedInSubject.next(true);
          this.authService.id = response.data.id;
          this.authService.setRole(response.data.role);
          this.authService.roleSubject.next(response.data.role);
          this.authService.fullNameSubject.next(response.data.fullName);
        }

        return response;
      }));

  }
}
