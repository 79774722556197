import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICategory } from 'src/app/interfaces/category.interface';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Status } from 'src/app/configs/status';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  public messagesSubject: BehaviorSubject<ICategory[]>;
  public messages: Observable<ICategory[]>;

  // Sidebar counter
  public countNewMessagesSubject: BehaviorSubject<number>;
  public countNewMessages: Observable<number>;

  public cache = {
    messages: null
  };

  constructor(private http: HttpClient) {
    this.messagesSubject = new BehaviorSubject<ICategory[]>(null);
    this.messages = this.messagesSubject.asObservable();

    // Sidebar info
    this.countNewMessagesSubject = new BehaviorSubject<number>(0);
    this.countNewMessages = this.countNewMessagesSubject.asObservable();
  }

  public count() {
    return this.http.get(`${environment.apiUrl}/messages/count/new`)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.countNewMessagesSubject.next(response.data.count);
        }
      });
  }

  public seen(id: number) {
    return this.http.get(`${environment.apiUrl}/messages/seen/${id}`);
  }

  public read(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.messages !== null) {
      const cache = this.cache.messages.find(c => c.id_message === id);

      if (cache) {
        const r: IResponse = {
          success: true,
          status: Status.OK,
          data: cache
        };

        return of(r);
      }
    }

    return this.http.get(`${environment.apiUrl}/messages/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/messages/delete/${id}`);
  }

  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.messages !== null) {
      this.messagesSubject.next(this.cache.messages);
      return of(this.cache.messages);
    }

    return this.http.get(`${environment.apiUrl}/messages/all`)
      .pipe(map((response: IResponse) => {
        this.cache.messages = response.data;
        this.messagesSubject.next(response.data || []);
        this.count();
      })).subscribe();
  }

  public empty() {
    this.messagesSubject.next([]);
  }

  public purge() {
    this.cache.messages = null;
    this.empty();
    this.list(true);
  }
}

