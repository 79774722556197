import { Component, OnDestroy } from '@angular/core';
import { OrdersService } from 'src/app/modules/orders/services/orders.service';
import { MessagesService } from 'src/app/modules/messages/services/messages.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Roles } from 'src/app/configs/roles';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {
  public open = false;
  public countNewOrders = 0;
  public countNewMessages = 0;
  public role;
  public roles;
  private subscriptions: Subscription[] = [];

constructor(private ordersService: OrdersService, private messagesService: MessagesService, private auth: AuthService,) {
    const countOrderSub = this.ordersService.countNewOrders.subscribe((count) => {
      this.countNewOrders = count;
    });
    this.subscriptions.push(countOrderSub);
    this.ordersService.count();

    const countMessSub = this.messagesService.countNewMessages.subscribe((count) => {
      this.countNewMessages = count;
    });
    this.subscriptions.push(countMessSub);
    this.messagesService.count();
    this.auth.role.subscribe((value)=>{
      this.role = value;
    });
    this.roles = Roles;
  }


  public ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
