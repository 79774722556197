import { Component, OnInit, OnDestroy } from '@angular/core';
import { article_categories } from 'src/app/interfaces/article-categories.interface';
import { Base } from 'src/app/common/base.component';
import { ArticleCategoriesService } from '../service/article-categories.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-article-categories-list',
  templateUrl: './article-categories-list.component.html',
  styleUrls: ['./article-categories-list.component.scss']
})
export class ArticleCategoriesListComponent extends Base implements OnInit, OnDestroy {
  public articleCategories: article_categories[]=[];
  constructor(private articleCategoriesService: ArticleCategoriesService) { super(); }

  ngOnInit() {
    this.getArticleCategories();
  }

  public ngOnDestroy() {
    this.articleCategories = [];
    this.articleCategoriesService.empty();
    this.unsubscribe();
  }

  public refresh() {
    this.busySync();
    this.articleCategoriesService.list(true);
  }
  private getArticleCategories() {
    this.busy();

    this.subscriptions.push(this.articleCategoriesService.articleCategories.subscribe((articleCategories) => {
      if (articleCategories !== null) {
        this.articleCategories = articleCategories;
        this.idle();
      }
    }, this.onError.bind(this)));

    this.subscriptions.push(this.articleCategoriesService.list() as Subscription);
  }

}
