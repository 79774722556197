import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { UsersService } from '../services/users.service';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html'
})
export class UsersListComponent extends Base implements OnInit, OnDestroy {

  public users: IUser[] = [];

  constructor(private usersService: UsersService) {
    super();
  }

  public ngOnInit() {
    this.getUsers();
  }

  public ngOnDestroy() {
    this.users = [];
    this.usersService.empty();
    this.unsubscribe();
  }

  public refresh() {
    this.busySync();
    this.usersService.list(true);
  }

  private getUsers() {
    this.busy();

    this.subscriptions.push(this.usersService.users.subscribe((users) => {
      if (users !== null) {
        this.users = users;
        this.idle();
      }
    }, this.onError.bind(this)));

    this.subscriptions.push(this.usersService.list() as Subscription);
  }

}
