import { Base } from 'src/app/common/base.component';
import { CategoriesSizesService } from '../../services/categories-sizes.service';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ICategorySize } from 'src/app/interfaces/category-size.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-categories-sizes-form',
  templateUrl: './categories-sizes-form.component.html'
})
export class CategoriesSizesFormComponent extends Base implements OnDestroy {

  public category: number;

  constructor(
    private formBuilder: FormBuilder,
    private sizeService: CategoriesSizesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    super();

    const fields: ICategorySize = {
      size: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
    this.idle();
  }

  public edit(s: ICategorySize) {
    this.id = s.id_size;
    this.category = s.id_category;
    this.f.size.setValue(s.size);
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();
    data.append('id_category', this.category as any);

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  private create(data: FormData) {

    this.busySave();

    return this.sizeService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Mărime salvată!', 'Salvat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eroare nu a putut fi salvat!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();
    return this.sizeService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Mărime actualizată!', 'Modificat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eraore nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  public delete() {
    this.busyDelete();
    return this.sizeService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Mărime șters!', 'Șters!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Marimea nu a fost ștersă!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }
}
