export const OrderDeliveryStatus = [
    {
        ID: 0,
        LABEL: 'Pregătire colet'
    },
    {
        ID: 1,
        LABEL: 'Predat la curier'
    },
    {
        ID: 2,
        LABEL: 'Livrat'
    }
];
