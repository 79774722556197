import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { articles } from 'src/app/interfaces/articles.interface';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Status } from 'src/app/configs/status';
@Injectable({
  providedIn: 'root'
})
export class BlogService {
  public articlesSubject: BehaviorSubject<articles[]>;
  public articles: Observable<articles[]>;
  public deleteSubject: BehaviorSubject<String>;
  public cache = {
    articles: null
  };
  constructor(private http: HttpClient) {
    this.articlesSubject = new BehaviorSubject<articles[]>(null);
    this.articles = this.articlesSubject.asObservable();
    this.deleteSubject=new BehaviorSubject<String>(null);
    this.deleteSubject.subscribe((value)=>{
      console.log("aici in behaviour", value);
      if(value!=null){
        this.deletePicture(value).subscribe((value)=>{
          console.log("dupa delete");
        });
      }
      
    });
  }

  public read(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.articles !== null) {
      const cache = this.cache.articles.find(c => c.id_article === id);

      if (cache) {
        const r: IResponse = {
          success: true,
          status: Status.OK,
          data: cache
        };

        return of(r);
      }
    }

    return this.http.get(`${environment.apiUrl}/blog/read/${id}`);
  }


  public unique(data: FormData, column: string, id?) {
    if (id) {
      return this.http.post(`${environment.apiUrl}/blog/unique/update/${column}/${id}`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/blog/unique/insert/${column}`, data);
    }
  }
  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/blog/create`, data);
  }


  public empty() {
    this.articlesSubject.next([]);

  }
  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.articles !== null) {
      this.articlesSubject.next(this.cache.articles);
      return of(this.cache.articles);
    }

    return this.http.get(`${environment.apiUrl}/blog/all`)
    .pipe(map((response: IResponse) => {
      this.cache.articles = response.data;
      this.articlesSubject.next(response.data || []);
    })).subscribe();
  }
  public purge() {
    this.cache.articles = null;
    this.empty();
    this.list(true);
  }
  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/blog/update/${id}`, data);
  }

  public last_id(){
    return this.http.get(`${environment.apiUrl}/blog/get_last_id`);
  }

  public deletePicture(name: String){
    return this.http.delete(`${environment.apiUrl}/blog/delete_picture/${name}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/blog/delete/${id}`);
  }

  public emit(msg: String){
    this.deleteSubject.next(msg);
  }
  public get_category_articles(){
    return this.http.get(`${environment.apiUrl}/article_categories/categories`);
  }

}
