import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsFormComponent } from './clients-form/clients-form.component';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserFriends, faPlus, faUserEdit, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { ClientsComponent } from './clients.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ClientsFormComponent,
    ClientsListComponent,
    ClientsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    AppRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ClientsFormComponent,
    ClientsListComponent
  ],
})
export class ClientsModule {
  constructor() {
    library.add(faUserFriends, faPlus, faUserEdit, faUserTie);
  }
}
