import { FormGroup, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export class Base {

    /**
     * Little helper to get form controlls
     */
    public get f() {
        return this.form.controls;
    }

    /**
     * Database entry id (:id param)
     */
    public id: number;

    /**
     * Trigger validation
     */
    public submitted = false;

    /**
     * UI Blocking
     */
    public loading = true;
    public loadingText = 'Se încarcă';

    /**
     * Pagination
     */
    public page = 1;
    public pageSize = 10;

    /**
     * The form
     */
    public form: FormGroup;

    /**
     * General modal reference. Used to refer our add/edit product modal.
     */
    public modal: NgbModalRef;

    public subscriptions: Subscription[] = [];

    public direction = '';

    public sortColumn;
    public rotate: { [key: string]: string } = { asc: 'desc', desc: 'asc', '': 'asc' };

    public searchTerm;

    public busy(loadingText?: string) {
        this.loading = true;
        this.loadingText = loadingText || 'Se încarcă';
    }

    public busySync() {
        this.busy('Se sincronizează');
    }

    public busyUpdate() {
        this.busy('Se actualizează');
    }

    public busySave() {
        this.busy('Se salvează');
    }

    public busyDelete() {
        this.busy('Se șterge');
    }

    public busyAdd() {
        this.busy('Se adaugă');
    }

    public idle() {
        this.loading = false;
        this.loadingText = '';
    }

    public noop() {
        // not implemented
    }

    public onError(error?: Error) {
        this.idle();
        // Http error is handled by interception
    }

    /**
     * @returns true if form is in edit mode
     */
    public isEdit() {
        return !!this.id;
    }

    public unsubscribe() {
        this.subscriptions.forEach(s => s.unsubscribe && s.unsubscribe());

        if (this.modal) { this.modal.close(); }
    }

    /**
     * On component destroy unsubscribe.
     */
    // tslint:disable-next-line:use-lifecycle-interface
    public ngOnDestroy() {
        this.unsubscribe();
    }

    public getFormData() {
        const data = new FormData();

        for (const ctrlName in this.f) {
            if (this.f.hasOwnProperty(ctrlName)) {
                data.append(ctrlName, this.f[ctrlName].value);
            }
        }

        return data;
    }

    public setFormData(data) {
        for (const key in data) {
            if (this.f.hasOwnProperty(key) && data.hasOwnProperty(key)) {
                this.f[key].setValue(data[key]);
            }
        }
    }

    /**
     * Returns a sorted collection
     * @param collection the collection to sort
     * @param key property to compare
     */
    public sort(collection, key) {
        return collection.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            // a == b
            return 0;
        });
    }

    /**
     * Fetches the name of a form control
     * @param control form control
     */
    public getControlName(control: AbstractControl): string | null {
        const group = control.parent as FormGroup;

        if (!group) {
            return null;
        }

        let name: string;

        Object.keys(group.controls).forEach(key => {
            const childControl = group.get(key);

            if (childControl !== control) {
                return;
            }

            name = key;
        });

        return name;
    }

    public tableSort(column) {
        this.sortColumn = column;
        this.direction = this.rotate[this.direction];
    }

    public sortCSS(column) {
        const css = [];

        if (this.sortColumn === column) {
            css.push('sorted');
            css.push(this.direction);
        }

        return css.join(' ');
    }
    public compare(v1, v2) { return v1 < v2 ? -1 : v1 > v2 ? 1 : 0; }

    public search($event) {
        if (typeof $event === 'string') {
            this.searchTerm = $event;
        }
    }

    public normalize(value) {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}

