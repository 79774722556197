import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ICharacteristic } from 'src/app/interfaces/characteristic.interface';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoriesCharacteristicsService {

  public charSubject: BehaviorSubject<ICharacteristic[]>;
  public characteristics: Observable<ICharacteristic[]>;

  public cache = {
    chars: []
  };

  constructor(private http: HttpClient) {
    this.charSubject = new BehaviorSubject<ICharacteristic[]>(null);
    this.characteristics = this.charSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/categories/characteristics/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/categories/characteristics/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/categories/characteristics/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/categories/characteristics/delete/${id}`);
  }

  public list(idCategory: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.chars && this.cache.chars[idCategory]) {
      this.charSubject.next(this.cache.chars[idCategory]);
      return of(this.cache.chars);
    }

    return this.http.get(`${environment.apiUrl}/categories/characteristics/all/${idCategory}`)
      .pipe(map((response: IResponse) => {
        this.cache.chars[idCategory] = response.data;
        this.charSubject.next(response.data || []);
      })).subscribe();
  }

  public empty() {
    this.charSubject.next([]);
  }
}
