import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { IProductSize } from 'src/app/interfaces/product-size.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsColorsService {


  public colorsSubject: BehaviorSubject<IProductSize[]>;
  public colors: Observable<IProductSize[]>;

  public cache = {
    colors: []
  };

  constructor(private http: HttpClient) {
    this.colorsSubject = new BehaviorSubject<IProductSize[]>(null);
    this.colors = this.colorsSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/products/colors/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/products/colors/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/products/colors/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/products/colors/delete/${id}`);
  }

  public list(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.colors && this.cache.colors[id]) {
      this.colorsSubject.next(this.cache.colors[id]);

      return of(this.cache.colors);
    }

    return this.http.get(`${environment.apiUrl}/products/colors/all/${id}`)
      .pipe(map((response: IResponse) => {
        this.cache.colors[id] = response.data;
        this.colorsSubject.next(response.data || []);
      })).subscribe();
  }

  public empty() {
    this.colorsSubject.next([]);
  }
}
