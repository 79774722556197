import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Roles } from 'src/app/configs/roles';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { AccountService } from 'src/app/modules/account/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public id;

  public isLoggedInSubject;
  public isLoggedIn: Observable<boolean>;

  public roleSubject;
  public role;

  public fullNameSubject;
  public fullName;

  public returnUrl;
  public sessionChecked: boolean;

  // tslint:disable-next-line:variable-name
  private _role = null;

  constructor(private http: HttpClient, private router: Router) {
    this.setObservables();
  }


  private setObservables() {

    // Is logged in observable
    this.isLoggedInSubject = new BehaviorSubject<boolean>(false);
    this.isLoggedIn = this.isLoggedInSubject.asObservable();

    // Role observable
    this.roleSubject = new BehaviorSubject<Roles>(null);
    this.role = this.roleSubject.asObservable();

    // Name observable
    this.fullNameSubject = new BehaviorSubject<string>('');
    this.fullName = this.fullNameSubject.asObservable();
  }

  public setRole(role: string){
    this._role = role;
  }

  public hasSession(router, url) {

    this.returnUrl = url !== 'autentificare' ? url : 'comenzi';

    return this.sessionChecked || this.http.get<any>(`${environment.apiUrl}/account/session`)
      .pipe(map(response => {

        if (!response.success) {
          router.navigate(['/autentificare']);
          return;
        }

        this.setRole(response.data.role);
        this.roleSubject.next(response.data.role);
        this.id = response.data.id;

        this.setVerified();

        return response.success;

      }), catchError((error) => {
        console.error(error);
        router.navigate(['/autentificare']);
        return of(false);
      }));
  }

  public hasPermission(roles: Roles[]) {

    if (roles && roles.indexOf(this._role) === -1) {
      this.router.navigate(['401']);
      return false;
    }
    return true;
  }


  public setVerified() {
    this.sessionChecked = true;
    this.isLoggedInSubject.next(true);
  }

  public logout() {
    this.http.get<any>(`${environment.apiUrl}/account/logout`).subscribe();
    this.isLoggedInSubject.next(false);

     //this.roleSubject.next(false);
   // localStorage.removeItem("role");
  }

  public getLoginId() {
    return this.id;
  }
}
