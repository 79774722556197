import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesSizesFormComponent } from '../categories-sizes-form/categories-sizes-form.component';
import { ICategorySize } from 'src/app/interfaces/category-size.interface';
import { CategoriesSizesService } from '../../services/categories-sizes.service';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-categories-sizes-list',
  templateUrl: './categories-sizes-list.component.html'
})
export class CategoriesSizesListComponent extends Base implements OnInit, OnDestroy {

  @Input() category;

  public sizes: ICategorySize[] = [];

  constructor(
    private modalService: NgbModal,
    private sizeService: CategoriesSizesService
  ) {
    super();
  }

  public ngOnInit() {
    this.sizes = [];
    this.getSizes();
  }

  public ngOnDestroy() {
    this.sizes = [];
    this.sizeService.empty();
    this.unsubscribe();
  }

  private getSizes() {
    this.busy();
    this.subscriptions.push(this.sizeService.sizes
      .subscribe((sizes: ICategorySize[]) => {
        if (sizes !== null) {
          this.sizes = sizes;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.sizeService.list(this.category);
  }

  public newSize() {
    this.modal = this.modalService
      .open(CategoriesSizesFormComponent, {
        backdrop: 'static'
      });
    this.modal.componentInstance.category = this.category;
    this.checkRefresh(this.modal);
  }

  public editSize(size: ICategorySize) {
    this.modal = this.modalService.open(CategoriesSizesFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.edit(size);
    this.checkRefresh(this.modal);
  }

  public refresh() {
    this.busySync();
    this.sizeService.list(this.category, true);
  }

  private checkRefresh(modal) {
    modal.result.then((reason) => (reason === 'refresh') && this.refresh());
  }

}
