import {
  FormBuilder,
  Validators
  } from '@angular/forms';
import { Base } from 'src/app/common/base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProduct } from 'src/app/interfaces/product.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdersService } from '../../services/orders.service';
import { ProductsService } from 'src/app/modules/products/services/products.service';
import { ProductsStockService } from 'src/app/modules/products/services/products-stocks.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-orders-products-form',
  templateUrl: './orders-products-form.component.html'
})
export class OrdersProductsFormComponent extends Base implements OnInit, OnDestroy {

  /**
   * @column id_order_products
   * Used for foreing key
   */
  public idOrderProduct: number;

  /**
   * @column id_order
   * Used for foreing key
   */
  public idOrder: number;

  /**
   * @column id_product_stock
   * Used for foreing key
   */
  public idStock: number;

  /**
   * Dropdown of stocks based on selected product
   */
  public stock: any[];

  /**
   * All available products
   */
  public products: any[];

  constructor(
    private formBuilder: FormBuilder,
    private ordersService: OrdersService,
    private productsService: ProductsService,
    private stocksService: ProductsStockService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    super();
    // Init form as new
    this.buildForm();
  }

  /**
   * Create a form group
   */
  private buildForm() {

    // must match database columns
    const fields = {
      id_product: ['', Validators.required],
      id_product_stock: ['', Validators.required],
      quantity: ['', Validators.required],
      price: [0, Validators.required]
    };

    this.form = this.formBuilder.group(fields);

    // price is read only
    this.f.price.disable({ onlySelf: true });
  }

  /**
   * ON init fetch products
   */
  public ngOnInit() {

    // On products bind to dropdown
    this.subscriptions.push(
      this.productsService.products.subscribe((products: IProduct[]) => {
        if (products !== null) {
          this.products = products;
          this.idle();
        }
      }, this.onError.bind(this)));

    // On stocks bind to dropdown
    this.subscriptions.push(
      this.stocksService.stocks.subscribe((stocks) => {
        if (stocks) {
          this.stock = stocks;

          // Is edit mode - preselect a stock
          if (this.idStock) {
            this.f.id_product_stock.setValue(this.idStock);
          }

          this.idle();
        }
      }));

    // Fetch Products
    this.subscriptions.push(this.productsService.list() as Subscription);
  }

  /**
   * Edit mode fill the inputs
   */
  public edit(p) {
    this.f.id_product.setValue(p.id_product);
    this.f.quantity.setValue(p.order_quantity);
    this.f.price.setValue(p.order_price);

    // Manually trigger product select to fetch stocks
    this.onSelectProduct(true);
  }

  /**
   * On product select fetch stocks
   * @param noPriceUpdate to update the price field or not
   */
  public onSelectProduct(noPriceUpdate?: boolean) {
    this.stock = [];
    this.f.id_product_stock.setValue('');

    if (!noPriceUpdate) {
      const selectedProd = this.products.find((p) => p.id_product === this.f.id_product.value);

      // If we have sale price. Use it
      if (selectedProd.product_sale_price > 0) {
        this.f.price.setValue(selectedProd.sale_price);
      } else {
        this.f.price.setValue(selectedProd.price);
      }
    }

    // Fetch stocks for selected product
    this.stocksService.list(this.f.id_product.value);
  }

  /**
   * @returns true if form is in edit mode
   */
  public isEdit() {
    return !!this.idOrderProduct;
  }


  /**
   * Form submit.
   */
  public submit() {

    // triger validation
    this.submitted = true;

    // stop here if form is invalid or no stock available
    if (this.form.invalid || (!this.stock || this.stock.length === 0)) {
      return;
    }

    // Build post data
    const data = this.getFormData();
    data.append('id_order', this.idOrder.toString());

    // Start Update or Create
    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  /**
   * Sends data to insert in database
   * @param data Form Data
   */
  private create(data: FormData) {
    this.busy('Se adaugă');

    return this
      .ordersService
      .createProduct(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Produs adăugat!', 'Salvat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eroare nu a putut fi adaugat!', 'Eroare!');
        }

        this.idle();

      }, this.onError.bind(this));
  }

  private update(data) {
    this.busy('Se actualizează');

    return this.ordersService
      .updateProduct(data, this.idOrderProduct)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Produs a fost actualizat!', 'Modificat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Produsult nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();

      }, this.onError.bind(this));
  }

  public delete() {
    this.busy('Se șterge');

    return this.ordersService
      .deleteProduct(this.idOrderProduct)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Produsul a fost ștears!', 'Șters!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Comanda nu a fost ștearsă!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }
}
