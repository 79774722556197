import { Base } from 'src/app/common/base.component';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { IProductColor } from 'src/app/interfaces/product-color.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsColorsFormComponent } from '../products-colors-form/products-colors-form.component';
import { ProductsColorsService } from '../../services/products-colors.service';
import { ProductsStockService } from '../../services/products-stocks.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-products-colors-list',
  templateUrl: './products-colors-list.component.html',
  styleUrls: ['./products-colors-list.component.scss']
})
export class ProductsColorsListComponent extends Base implements OnInit, OnDestroy {


  @Input() product: number;
  @Input() category: number;

  public deleting = false;
  public colors: IProductColor[] = [];

  constructor(
    private modalService: NgbModal,
    private colorService: ProductsColorsService,
    private stockService: ProductsStockService) {
    super();
  }

  public ngOnInit() {
    this.colors = [];
    this.getColors();
  }

  public ngOnDestroy() {
    this.colors = [];
    this.colorService.empty();
    this.unsubscribe();
  }

  private getColors() {
    this.busy();
    this.subscriptions.push(this.colorService.colors
      .subscribe((colors: IProductColor[]) => {
        if (colors !== null) {
          this.colors = colors;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.subscriptions.push(this.colorService.list(this.product) as Subscription);
  }

  public newColor() {

    this.modal = this.modalService.open(ProductsColorsFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.idProduct = this.product;

    this.checkRefresh(this.modal);
  }

  public editColor(color) {
    this.modal = this.modalService.open(ProductsColorsFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.edit(color);

    this.checkRefresh(this.modal);
  }

  public refresh() {
    this.busySync();
    this.colorService.list(this.product, true);
    this.stockService.list(this.product, true);
  }

  private checkRefresh(modal) {
    modal.result.then((reason) => (reason === 'refresh') && this.refresh());
  }

}
