import { Base } from 'src/app/common/base.component';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImagesService } from '../../services/images.service';
import { IPicture } from 'src/app/interfaces/images.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsImagesFormComponent } from '../products-images-form/products-images-form.component';
import { ProductsImagesViewComponent } from '../products-images-view/products-images-view.component';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-images-list',
  templateUrl: './products-images-list.component.html',
  styleUrls: ['./products-images-list.component.scss']
})
export class ProductsImagesListComponent extends Base implements OnInit, OnDestroy {

  @Input() product;
  public images: IPicture[] = [];
  public deleting = false;

  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
    private imageService: ImagesService) {
    super();
  }

  public ngOnInit() {
    this.images = [];
    this.getCharacteristics();
  }

  public ngOnDestroy() {
    this.images = [];
    this.imageService.empty();
    this.unsubscribe();
  }

  public newImages() {
    this.modal = this.modalService.open(ProductsImagesFormComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    this.modal.componentInstance.product = this.product;

    this.checkRefresh(this.modal);
  }

  public view(fileName) {
    const imgLargeSrc = environment.siteUrl + 'public/products/large/' + fileName;
    this.modal = this.modalService.open(ProductsImagesViewComponent, { size: 'lg' });
    this.modal.componentInstance.imageSrc = imgLargeSrc;
  }

  public imageSrc(fileName: string) {
    return environment.siteUrl + 'public/products/small/' + fileName;
  }

  public delete(img: IPicture) {
    this.deleting = true;
    this.subscriptions.push(this.imageService.delete(img.id_picture)
      .subscribe((response: IResponse) => {
        if (response.success) {
          const index = this.images.indexOf(img);
          this.images.splice(index, 1);
          this.toastr.success('Imaginea a fost ștearsă.', 'Șters');
        } else {
          this.toastr.error('Imaginea nu a fost ștearsă.', 'Eroare');
        }

        this.deleting = false;
      }, (error) => this.deleting = false));
  }

  public refresh() {
    this.busySync();

    this.imageService.list(this.product, true);
  }

  private getCharacteristics() {
    this.busy();

    this.subscriptions.push(this.imageService.images
      .subscribe((images) => {
        if (images !== null) {
          this.images = images;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.subscriptions.push(this.imageService.list(this.product) as Subscription);
  }

  private checkRefresh(modalRef) {
    modalRef.result.then((reason) => {
      if (reason === 'refresh') {
        this.refresh();
      }
    });
  }
}
