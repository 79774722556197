import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faCoffee,
  faStar,
  faShoppingCart,
  faUserTie,
  faTags,
  faPalette,
  faEnvelope,
  faUserFriends,
  faSearch,
  faPowerOff,
  faUser,
  faRuler,
  faCog,
  faLayerGroup,
  faBars,
  faListAlt 
} from '@fortawesome/free-solid-svg-icons';
import { faWordpress} from '@fortawesome/free-brands-svg-icons';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IsBusyComponent } from './is-busy/is-busy.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { TableFilterPipe } from '../pipes/table-filter.pipe';
import { OrdersFilterPipe } from '../pipes/orders-filter.pipe';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableSortPipe } from '../pipes/table-sort.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    IsBusyComponent,
    ConfirmDeleteComponent,
    TableFilterPipe,
    TableSortPipe,
    TableFilterComponent,
    OrdersFilterPipe
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    IsBusyComponent,
    ConfirmDeleteComponent,
    TableFilterComponent,
    TableFilterPipe,
    TableSortPipe,
    OrdersFilterPipe
  ]
})
export class LayoutModule {
  constructor() {
    library.add(faCoffee, faStar, faShoppingCart, faCog,
      faUserTie, faTags, faPalette, faEnvelope, faUserFriends, faSearch, faUser, faPowerOff, faRuler, faLayerGroup, faBars, faWordpress, faListAlt);
      //dom.watch();
    }
}
