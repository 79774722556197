import { Base } from 'src/app/common/base.component';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { IProductSize } from 'src/app/interfaces/product-size.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsSizesFormComponent } from '../products-sizes-form/products-sizes-form.component';
import { ProductsSizesService } from '../../services/products-sizes.service';
import { ProductsStockService } from '../../services/products-stocks.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-sizes-list',
  templateUrl: './products-sizes-list.component.html'
})
export class ProductsSizesListComponent extends Base implements OnInit, OnDestroy {

  @Input() product: number;
  @Input() category: number;

  public deleting = false;
  public sizes: IProductSize[] = [];

  constructor(
    private modalService: NgbModal,
    private sizeService: ProductsSizesService,
    private toastr: ToastrService,
    private stockService: ProductsStockService
  ) {
    super();
  }

  public ngOnInit() {
    this.sizes = [];
    this.getSizes();
  }

  public ngOnDestroy() {
    this.sizes = [];
    this.sizeService.empty();
    this.unsubscribe();
  }

  private getSizes() {
    this.busy();
    this.subscriptions.push(this.sizeService.sizes
      .subscribe((sizes: IProductSize[]) => {
        if (sizes !== null) {
          this.sizes = sizes;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.subscriptions.push(this.sizeService.list(this.product) as Subscription);
  }

  public newSize() {
    this.modal = this.modalService
      .open(ProductsSizesFormComponent, {
        backdrop: 'static'
      });
    this.modal.componentInstance.idProduct = this.product;
    this.modal.componentInstance.idCategory = this.category;

    this.checkRefresh(this.modal);
  }

  public refresh() {
    this.busySync();
    this.sizeService.list(this.product, true);
    this.stockService.list(this.product, true);
  }

  public delete(s) {
    this.busyDelete();
    this.deleting = true;
    this.subscriptions.push(
      this.sizeService
        .delete(s.id_product_size)
        .subscribe((response: IResponse) => {
          if (response.success) {
            this.toastr.success('Mărimea a fost ștearsă!', 'Șters!');
            this.stockService.list(this.product, true);
            this.refresh();
          } else {
            this.toastr.error('Marimea nu a fost ștersă!', 'Eroare');
          }

          this.deleting = false;
          this.idle();
        }, (error) => {
          this.deleting = false;
          this.onError(error);
        }));
  }

  private checkRefresh(modal) {
    modal.result.then((reason) => (reason === 'refresh') && this.refresh());
  }

}
