import { Component, OnInit, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-images-form',
  templateUrl: './products-images-form.component.html',
  styleUrls: ['./products-images-form.component.scss']
})
export class ProductsImagesFormComponent implements OnInit {

  public product;
  public loading = false;
  public disabled = false;

  private hasUploaded = false;
  public config: DropzoneConfigInterface;

  @ViewChild(DropzoneComponent, { static: false }) componentRef?: DropzoneComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit() {
    this.config = {
      url: `${environment.apiUrl}/products/pictures/upload/${this.product}`,
      withCredentials: true,
      clickable: true,
      maxFiles: null,
      autoReset: null,
      errorReset: null,
      cancelReset: null,
      headers: {
        'Cache-Control': null,
        'X-Reauest-With': null
      }
    };
  }

  public close() {
    if (this.hasUploaded) {
      this.activeModal.close('refresh');
    } else {
      this.activeModal.close('cancel');
    }
  }
  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
    // this.loading = true;
  }

  public onUploadError(args: any): void {
    // this.loading = false;
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    // this.loading = false;
    const response: IResponse = args[1];
    if (response.success) {
      this.hasUploaded = true;
    } else {
      this.toastr.error(response.data.error, 'Eroare');
    }
  }
}
