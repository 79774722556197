import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Base } from 'src/app/common/base.component';
import { ClientsService } from '../../clients/services/clients.service';
import { Component, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { IResponse } from 'src/app/interfaces/response.interface';
import { MainGroups } from 'src/app/configs/main-groups';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDeliveryStatus } from 'src/app/configs/order-delivery-status';
import { OrderPaymentStatus } from 'src/app/configs/order-payment-status';
import { OrderPaymentType } from 'src/app/configs/order-payment-type';
import { OrdersProductsFormComponent } from './orders-products-form/orders-products-form.component';
import { OrdersService } from '../services/orders.service';
import { OrderStatus } from 'src/app/configs/order-status';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-orders-form',
  templateUrl: './orders-form.component.html',
  styleUrls: ['./orders-form.component.scss']
})
export class OrdersFormComponent extends Base implements OnDestroy {

  /**
   * @column id_order
   */
  public idOrder: number;

  /**
   * Dropdown clients source.
   */
  public clients = [];

  /**
   * Order products table source
   */
  public products = [];

  /**
   * Dropdown order status source
   */
  public orderStatuses = OrderStatus;

  /**
   * Dropdown payment type source
   */
  public paymentTypes = OrderPaymentType;

  /**
   * Dropdown payment status source
   */
  public paymentStatuses = OrderPaymentStatus;

  /**
   * Dropdown delivery status source
   */
  public deliveryStatuses = OrderDeliveryStatus;

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrdersService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    super();
    // Init form group
    this.buildForm();

    // Capture route event to fetch id and get order data
    this.subscriptions.push(
      this
        .router
        .events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((val) => {

          // Same route no need to fetch
          if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

          // order_no from url param
          this.id = this.route.snapshot.params.id;

          // Set edit mode
          if (this.id) {
            this.f.new_order.setValue(false);

            // Fetch order data
            this.getOrder();

          } else {

            // New order mode

            // GET CATEGORY
            this.subscriptions.push(
              this
                .clientsService
                .clients
                .subscribe(clients => {

                  if (clients != null) {
                    this.clients = this.sort(clients, 'first_name');
                  }

                }));

            this.subscriptions.push(this.clientsService.list() as Subscription);

            this.idle();
          }
        }, this.onError.bind(this)));

  }

  public labelMainGroup(groupID) {
    const group = MainGroups.find(g => g.ID === groupID);
    return group ? group.LABEL : 'Toti';
  }

  public paymentStatusCSS() {
    const status = this.paymentStatuses.find((p) => p.ID.toString() === this.f.payment_status.value.toString());
    if (status) {
      return status.css;
    }
  }

  public paymentStatus() {
    const status = this.paymentStatuses.find((p) => p.ID.toString() === this.f.payment_status.value.toString());
    if (status) {
      return status.LABEL;
    }
  }

  public orderStatus() {
    const status = this.orderStatuses.find((o) => o.ID.toString() === this.f.order_status.value.toString());
    if (status) {
      return status.LABEL;
    }
  }

  public paymentType() {
    return this.paymentTypes.find(p => p.ID.toString() === this.f.payment_type.value.toString()).LABEL;
  }

  public newProduct() {
    this.modal = this.modalService.open(OrdersProductsFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.idOrder = this.idOrder;

    this.checkRefresh(this.modal);
  }

  public editProduct(p) {
    this.modal = this.modalService.open(OrdersProductsFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.idOrder = this.idOrder;
    this.modal.componentInstance.idOrderProduct = p.id_order_products;
    this.modal.componentInstance.idStock = p.id_product_stock;
    this.modal.componentInstance.edit(p);
    this.checkRefresh(this.modal);
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  public getClient(key) {
    const client = this.clients.find((c) => c.id_client === this.f.id_client.value);

    if (client) {
      return client[key];
    }
  }

  public getTotal() {
    let total = 0;

    if (this.products) {
      this.products.forEach(p => total += parseFloat(p.order_price));
    }

    return total;
  }

  private buildForm() {

    // must match database columns
    const fields = {
      id_client: ['', Validators.required],
      order_status: [0, Validators.required],
      payment_status: ['started', Validators.required],
      payment_type: [0, Validators.required],
      order_delivery_status: [0, Validators.required],
      order_delivery_awb: [],
      order_delivery_cost: [0],
      comment: [''],
      new_order: [true]
    };

    this.form = this.formBuilder.group(fields);
  }

  private getOrder() {
    const connectorSub = this.orderService
      .read(this.id)
      .subscribe((response: IResponse) => {

        if (!response.success) {
          this.toastr.error('Comanda nu există!', 'Eroare!');
          this.router.navigate(['404']);
          return;
        }

        const order = response.data.order;
        const client = response.data.client;
        this.products = response.data.products;
        this.idOrder = order.id_order;
        this.clients = [client];

        for (const key in order) {
          if (this.f.hasOwnProperty(key) && order.hasOwnProperty(key)) {
            this.f[key].setValue(order[key]);
          }
        }

        for (const key in client) {
          if (this.f.hasOwnProperty(key) && client.hasOwnProperty(key)) {
            this.f[key].setValue(client[key]);
          }
        }

        // set readonly

        this.f.id_client.disable({ onlySelf: true });
        this.f.payment_type.disable({ onlySelf: true });

        if (parseInt(this.f.payment_type.value, 10) === 1 /* card */) {
          this.f.payment_status.disable({ onlySelf: true });
        }

        this.idle();
      }, this.onError.bind(this));

    this.subscriptions.push(connectorSub);
  }

  private create(data: FormData) {
    this.busySave();

    return this.orderService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.orderService.purge();
          this.toastr.success('Comandat a fost salvată!', 'Salvat!');
          this.router.navigate(['comenzi', 'modifica', response.data.id]);
        } else {
          this.toastr.error('Comandat nu a putut fi salvată!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();

    return this.orderService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.orderService.purge();
          this.toastr.success('Comandat a fost actualizată!', 'Modificat!');
          this.router.navigate(['comenzi', 'modifica', this.id]);

        } else {
          this.toastr.error('Comanda nu a putut fi actualizată!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  public delete() {
    this.busyDelete();

    return this.orderService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.orderService.purge();
          this.toastr.success('Comanda a fost ștearsă!', 'Șters!');
          this.router.navigate(['comenzi']);
        } else {
          this.toastr.error('Comanda nu a fost ștearsă!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  public refresh() {
    this.busySync();
    this.getOrder();
  }

  private checkRefresh(modal) {
    modal.result.then((reason) => (reason === 'refresh') && this.refresh());
  }
}
