import { Base } from 'src/app/common/base.component';
import { CategoriesCharacteristicsService } from 'src/app/modules/categories/services/categories-characteristics.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IProductProperty } from 'src/app/interfaces/product-property.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsPropertiesService } from '../../services/products-properties.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-properties-form',
  templateUrl: './products-properties-form.component.html'
})
export class ProductsPropertiesFormComponent extends Base implements OnInit, OnDestroy {

  public idProduct: number;
  public idCategory: number;

  public properties: IProductProperty[];

  constructor(
    private formBuilder: FormBuilder,
    private categoriesCharService: CategoriesCharacteristicsService,
    private propertiesService: ProductsPropertiesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    super();

    const fields = {
      id_characteristic: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
  }

  public ngOnInit() {
    this.busy();
    this.subscriptions.push(this.categoriesCharService.characteristics
      .subscribe((properties) => {
        if (properties) {
          this.properties = properties;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.subscriptions.push(this.categoriesCharService.list(this.idCategory) as Subscription);
  }

  public ngOnDestroy() {
    this.properties = [];
    this.unsubscribe();
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();
    data.append('id_product', this.idProduct as any);
    this.subscriptions.push(this.create(data));
  }

  private create(data: FormData) {

    this.busySave();
    return this.propertiesService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Proprietate adăugată!', 'Salvat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eroare nu a putut fi adaugată!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

}
