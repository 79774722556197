import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { IProductSize } from 'src/app/interfaces/product-size.interface';
import { HttpClient } from '@angular/common/http';
import { IProductStock } from 'src/app/interfaces/product-stock.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductsStockService {


  public stocksSubject: BehaviorSubject<IProductStock[]>;
  public stocks: Observable<IProductStock[]>;

  public cache = {
    stocks: []
  };

  constructor(private http: HttpClient) {
    this.stocksSubject = new BehaviorSubject<IProductStock[]>(null);
    this.stocks = this.stocksSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/products/stocks/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/products/stocks/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/products/stocks/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/products/stocks/delete/${id}`);
  }

  public list(id: number, purgeCache?: boolean) {

    // if (!purgeCache && this.cache.stocks && this.cache.stocks[id]) {
    //   this.stocksSubject.next(this.cache.stocks[id]);

    //   return of(this.cache.stocks);
    // }

    return this.http.get(`${environment.apiUrl}/products/stocks/all/${id}`)
      .pipe(map((response: IResponse) => {
        this.cache.stocks[id] = response.data;
        this.stocksSubject.next(response.data || [{
          color_code: null,
          color_ro: 'Fără culoare',
          id_product_color: '0',
          id_product_size: '0',
          quantity: 0,
          size: 'Fără mărime'
        }]);
      })).subscribe();
  }

  public unique(data: FormData, column: string) {
    return this.http.post(`${environment.apiUrl}/products/stocks/unique/insert/${column}`, data);
  }

  public empty() {
    this.stocksSubject.next([]);
  }
}
