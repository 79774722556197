export const ProductTypes = [
    {
        ID: 'incaltaminte',
        LABEL: 'Încălțăminte',
    }, {
        ID: 'accesorii',
        LABEL: 'Accesorii'
    }, {
        ID: 'imbracaminte',
        LABEL: 'Îmbrăcăminte'
    }
];
