import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IOrder } from 'src/app/interfaces/order.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Status } from 'src/app/configs/status';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {


  public ordersSubject: BehaviorSubject<IOrder[]>;
  public orders: Observable<IOrder[]>;

  public countNewOrdersSubject: BehaviorSubject<number>;
  public countNewOrders: Observable<number>;

  public cache = {
    orders: null,
    stock: null
  };

  constructor(private http: HttpClient) {
    this.ordersSubject = new BehaviorSubject<IOrder[]>(null);
    this.orders = this.ordersSubject.asObservable();

    this.countNewOrdersSubject = new BehaviorSubject<number>(0);
    this.countNewOrders = this.countNewOrdersSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/orders/create`, data);
  }

  public createProduct(data: FormData) {
    return this.http.post(`${environment.apiUrl}/orders-products/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/orders/update/${id}`, data);
  }

  public updateProduct(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/orders-products/update/${id}`, data);
  }

  public count() {
    return this.http.get(`${environment.apiUrl}/orders/count/new`)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.countNewOrdersSubject.next(response.data.count);
        }
      });
  }

  public read(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.orders !== null) {
      const cache = this.cache.orders.find(c => c.id_product === id);

      if (cache) {
        const r: IResponse = {
          success: true,
          status: Status.OK,
          data: cache
        };

        return of(r);
      }
    }

    return this.http.get(`${environment.apiUrl}/orders/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/orders/delete/${id}`);
  }

  public deleteProduct(id: number) {
    return this.http.delete(`${environment.apiUrl}/orders-products/delete/${id}`);
  }

  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.orders !== null) {
      this.ordersSubject.next(this.cache.orders);
      return of(this.cache.orders);
    }

    return this.http.get(`${environment.apiUrl}/orders/all`)
      .pipe(map((response: IResponse) => {
        this.cache.orders = response.data;
        this.ordersSubject.next(response.data || []);

        this.count();
      })).subscribe();
  }

  public empty() {
    this.ordersSubject.next([]);
  }

  public purge() {
    this.cache.orders = null;
    this.empty();
    this.list(true);
  }
}
