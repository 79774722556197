import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientsService } from '../services/clients.service';
import { IClient } from 'src/app/interfaces/client.interface';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html'
})
export class ClientsListComponent extends Base implements OnInit, OnDestroy {

  public clients: IClient[] = [];

  constructor(private clientsService: ClientsService) {
    super();
  }

  public ngOnInit() {
    // Fetch clients
    this.getClients();
  }

  public ngOnDestroy() {
    this.clients = [];
    this.clientsService.empty();
    this.unsubscribe();
  }

  public refresh() {
    this.busy('Se sincronizează');
    this.clientsService.list(true);
  }

  private getClients() {
    this.busy('Se încarcă');

    this.subscriptions.push(
      this.clientsService.clients.subscribe((clients) => {
        if (clients !== null) {
          this.clients = clients;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.clientsService.list();
  }
}

