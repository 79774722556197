import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleCategoriesComponent } from './article-categories.component';
import { ArticleCategoriesFormComponent } from './article-categories-form/article-categories-form.component';
import { ArticleCategoriesListComponent } from './article-categories-list/article-categories-list.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from 'src/app/layout/layout.module';

@NgModule({
  declarations: [ArticleCategoriesComponent, ArticleCategoriesFormComponent, ArticleCategoriesListComponent],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LayoutModule,
  ]
})
export class ArticleCategoriesModule { }
