export const OrderPaymentType = [
    {
        ID: 0,
        LABEL: 'Ramburs'
    },
    {
        ID: 1,
        LABEL: 'Card'
    }
];
