import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICategory } from 'src/app/interfaces/category.interface';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISize } from 'selenium-webdriver';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Status } from 'src/app/configs/status';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  public categoriesSubject: BehaviorSubject<ICategory[]>;
  public categories: Observable<ICategory[]>;

  public cache = {
    categories: null
  };

  constructor(private http: HttpClient) {
    this.categoriesSubject = new BehaviorSubject<ICategory[]>(null);
    this.categories = this.categoriesSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/categories/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/categories/update/${id}`, data);
  }

  public read(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.categories !== null) {
      const cache = this.cache.categories.find(c => c.id_category === id);

      if (cache) {
        const r: IResponse = {
          success: true,
          status: Status.OK,
          data: cache
        };

        return of(r);
      }
    }

    return this.http.get(`${environment.apiUrl}/categories/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/categories/delete/${id}`);
  }

  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.categories !== null) {
      this.categoriesSubject.next(this.cache.categories);
      return of(this.cache.categories);
    }

    return this.http.get(`${environment.apiUrl}/categories/all`)
      .pipe(map((response: IResponse) => {
        this.cache.categories = response.data;
        this.categoriesSubject.next(response.data || []);
      })).subscribe();
  }

  public unique(data: FormData, column: string, id?) {
    if (id) {
      return this.http.post(`${environment.apiUrl}/categories/unique/update/${column}/${id}`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/categories/unique/insert/${column}`, data);
    }
  }

  public empty() {
    this.categoriesSubject.next([]);
  }

  public purge() {
    this.cache.categories = null;
    this.empty();
    this.list(true);
  }
}
