export const OrderPaymentStatus = [
    {
        ID: 'started',
        LABEL: 'Neachitată',
        css: 'info'
    },
    {
        ID: 'paid_pending',
        LABEL: 'în așteptare',
        css: 'warning'
    },
    {
        ID: 'paid',
        LABEL: 'Achitată',
        css: 'success'
    },
    {
        ID: 'confirmed_pending',
        LABEL: 'Se verifică',
        css: 'warning'
    },
    {
        ID: 'confirmed',
        LABEL: 'Confirmată',
        css: 'success'
    },
    {
        ID: 'rejected',
        LABEL: 'Respinsă',
        css: 'danger'
    },
    {
        ID: 'canceled',
        LABEL: 'Anulată',
        css: 'danger'
    },
    {
        ID: 'error',
        LABEL: 'Eroare',
        css: 'danger'
    },
    {
        ID: 'credit',
        LABEL: 'Bani returnați',
        css: 'big-danger'
    }
];
