import { AppRoutingModule } from 'src/app/app-routing.module';
import { CommonModule } from '@angular/common';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LayoutModule } from 'src/app/layout/layout.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { MessagesComponent } from './messages.component';
import { MessagesListComponent } from './messages-list/messages-list.component';
import { MessagesViewComponent } from './messages-view/messages-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MessagesListComponent,
    MessagesViewComponent,
    MessagesComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    MessagesListComponent,
    MessagesViewComponent
  ]
})
export class MessagesModule {
  constructor() {
    library.add(faCommentDots);
  }
}
