import { AppRoutingModule } from 'src/app/app-routing.module';
import { CommonModule } from '@angular/common';
import { faTruck, faCalendarAlt, faMoneyCheckAlt, faCheck, faAsterisk, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from 'src/app/layout/layout.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { OrdersComponent } from './orders.component';
import { OrdersFormComponent } from './orders-form/orders-form.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrdersProductsFormComponent } from './orders-form/orders-products-form/orders-products-form.component';
import { NgxCurrencyModule } from 'ngx-currency';


export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: false
};

@NgModule({
  declarations: [
    OrdersFormComponent,
    OrdersListComponent,
    OrdersComponent,
    OrdersProductsFormComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  exports: [
    OrdersFormComponent,
    OrdersListComponent
  ],
  entryComponents: [
    OrdersProductsFormComponent
  ]
})
export class OrdersModule {

  constructor() {
    library.add(faTruck, faCalendarAlt, faMoneyCheckAlt, faCheck, faAsterisk, faHourglass);
  }
}
