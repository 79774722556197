import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { IProductSize } from 'src/app/interfaces/product-size.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsPropertiesService {


  public propertiesSubject: BehaviorSubject<IProductSize[]>;
  public properties: Observable<IProductSize[]>;

  public cache = {
    properties: {}
  };

  constructor(private http: HttpClient) {
    this.propertiesSubject = new BehaviorSubject<IProductSize[]>(null);
    this.properties = this.propertiesSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/products/properties/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/products/properties/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/products/properties/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/products/properties/delete/${id}`);
  }

  public list(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.properties && this.cache.properties[id]) {
      this.propertiesSubject.next(this.cache.properties[id]);

      return of(this.cache.properties);
    }

    return this.http.get(`${environment.apiUrl}/products/properties/all/${id}`)
      .pipe(map((response: IResponse) => {
        this.cache.properties[id] = response.data;
        this.propertiesSubject.next(response.data || []);
      })).subscribe();
  }

  public unique(data: FormData, column: string) {
    return this.http.post(`${environment.apiUrl}/products/properties/unique/insert/${column}`, data);
  }

  public empty() {
    this.propertiesSubject.next([]);
  }
}
