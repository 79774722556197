import { Base } from 'src/app/common/base.component';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { IProductProperty } from 'src/app/interfaces/product-property.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsPropertiesFormComponent } from '../products-properties-form/products-properties-form.component';
import { ProductsPropertiesService } from '../../services/products-properties.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-properties-list',
  templateUrl: './products-properties-list.component.html'
})
export class ProductsPropertiesListComponent extends Base implements OnInit, OnDestroy {

  @Input() product: number;
  @Input() category: number;

  public deleting = false;
  public properties: IProductProperty[] = [];

  constructor(
    private modalService: NgbModal,
    private propertyService: ProductsPropertiesService,
    private toastr: ToastrService
  ) {
    super();
  }

  public ngOnInit() {
    this.properties = [];
    this.getProperties();
  }

  public ngOnDestroy() {
    this.properties = [];
    this.propertyService.empty();
    this.unsubscribe();
  }

  private getProperties() {
    this.busy();
    this.subscriptions.push(this.propertyService.properties
      .subscribe((properties: IProductProperty[]) => {
        if (properties !== null) {
          this.properties = properties;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.subscriptions.push(this.propertyService.list(this.product) as Subscription);
  }

  public newSize() {
    this.modal = this.modalService
      .open(ProductsPropertiesFormComponent, {
        backdrop: 'static'
      });
    this.modal.componentInstance.idProduct = this.product;
    this.modal.componentInstance.idCategory = this.category;

    this.checkRefresh(this.modal);
  }

  public refresh() {
    this.busySync();
    this.subscriptions.push(this.propertyService.list(this.product, true) as Subscription);
  }

  public delete(s) {
    this.busyDelete();
    this.deleting = true;
    this.propertyService
      .delete(s.id_product_characteristic)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Mărimea a fost ștearsă!', 'Șters!');
          this.refresh();
        } else {
          this.toastr.error('Marimea nu a fost ștersă!', 'Eroare');
        }

        this.deleting = false;
        this.idle();
      }, (error) => {
        this.deleting = false;
        this.onError(error);
      });
  }

  private checkRefresh(modal) {
    modal.result.then((reason) => (reason === 'refresh') && this.refresh());
  }
}
