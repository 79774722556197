import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-categories',
  templateUrl: './article-categories.component.html',
  styleUrls: ['./article-categories.component.scss']
})
export class ArticleCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
