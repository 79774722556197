import { CategoriesComponent } from './categories.component';
import { CategoriesFormComponent } from './categories-form/categories-form.component';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { CategoriesSizesFormComponent } from './categories-form/categories-sizes-form/categories-sizes-form.component';
import { CategoriesSizesListComponent } from './categories-form/categories-sizes-list/categories-sizes-list.component';
import { CharacteristicsFormComponent } from './categories-form/characteristics-form/characteristics-form.component';
import { CharacteristicsListComponent } from './categories-form/characteristics-list/characteristics-list.component';
import { CommonModule } from '@angular/common';
import {
  faAlignRight,
  faBoxes,
  faLayerGroup,
  faPencilAlt,
  faPlus,
  faRulerVertical,
  faSquare,
  faSync,
  faTimes,
  faTrash,
  faTshirt
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from 'src/app/layout/layout.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    CategoriesFormComponent,
    CategoriesListComponent,
    CategoriesComponent,
    CharacteristicsListComponent,
    CharacteristicsFormComponent,
    CategoriesSizesListComponent,
    CategoriesSizesFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    NgbModule
  ],
  entryComponents: [
    CharacteristicsFormComponent,
    CategoriesSizesFormComponent
  ]
})
export class CategoriesModule {
  constructor() {
    library.add(faPlus, faTimes, faPencilAlt, faSync, faLayerGroup, faSquare, faTrash, faBoxes, faAlignRight,
      faRulerVertical, faTshirt);
  }
}
