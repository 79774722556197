import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CategoriesService } from '../services/categories.service';
import { ICategory } from 'src/app/interfaces/category.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { MainGroups } from 'src/app/configs/main-groups';
import { ProductTypes } from 'src/app/configs/product-types';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-categories-form',
  templateUrl: './categories-form.component.html'
})
export class CategoriesFormComponent extends Base implements OnDestroy {

  public mainGroups = MainGroups;
  public productTypes = ProductTypes;

  private debounceUniqueTimer;

  constructor(
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    super();
    this.buildForm();

    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {
        if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

        this.id = this.route.snapshot.params.id;

        if (this.id) {
          this.subscriptions.push(this.categoriesService
            .read(this.id)
            .subscribe((response: IResponse) => {

              if (!response.success) {
                this.toastr.error('Categoria nu exista!', 'Eroare!');
                this.router.navigate(['404']);
                return;
              }

              for (const key in response.data) {
                if (this.f.hasOwnProperty(key) && response.data.hasOwnProperty(key)) {
                  this.f[key].setValue(response.data[key]);
                }
              }

              this.idle();
            }, this.onError.bind(this)));

        } else {
          this.idle();
        }
      }));
  }


  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  public delete() {
    this.busyDelete();

    this.subscriptions.push(
      this.categoriesService
        .delete(this.id)
        .subscribe((response: IResponse) => {
          if (response.success) {
            this.categoriesService.purge();
            this.toastr.success('Categoria a fost ștearsă!', 'Șters!');
            this.router.navigate(['categorii']);
          } else {
            this.toastr.error('Categoria nu a fost stearsă!', 'Eroare');
          }

          this.idle();
        }, this.onError.bind(this)));
  }

  private buildForm() {
    // must match database collumns
    const fields: ICategory = {
      category_name_ro: ['', Validators.required],
      category_name_en: ['', Validators.required],
      url_ro: ['', Validators.required],
      url_en: ['', Validators.required],
      products_type: ['', Validators.required],
      main_group: ['', Validators.required],
      seo_category_title_ro: ['', Validators.required],
      seo_category_title_en: ['', Validators.required],
      seo_category_description_ro: ['', Validators.required],
      seo_category_description_en: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
    this.f.url_en.setAsyncValidators(this.isUnique.bind(this));
    this.f.url_ro.setAsyncValidators(this.isUnique.bind(this));
  }

  private create(data: FormData) {
    this.busySave();

    return this.categoriesService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.categoriesService.purge();
          this.toastr.success('Categoria a fost salvată!', 'Salvat!');
          this.router.navigate(['categorii', 'modifica', response.data.id]);
        } else {
          this.toastr.error('Categoria nu a putut fi salvată!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();

    return this.categoriesService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.categoriesService.purge();
          this.toastr.success('Categoria a fost actualizată!', 'Modificat!');
          this.router.navigate(['categorii', 'modifica', this.id]);

        } else {
          this.toastr.error('Categoria nu a putut fi actualizată!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private async isUnique(control: AbstractControl): Promise<any> {
    clearTimeout(this.debounceUniqueTimer);

    return new Promise((resolve, reject) => {
      this.debounceUniqueTimer = setTimeout(() => {
        const column = this.getControlName(control);
        const data = new FormData();
        data.append(column, control.value);

        this.categoriesService.unique(data, column, this.id)
          .subscribe((response: IResponse) => {
            if (response.success) {
              resolve(null);
            } else {
              resolve({ notUnique: true });
            }
          },
            (error) => {
              resolve({ notUnique: true });
            });
      }, 600);
    });
  }

}
