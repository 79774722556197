import { Base } from 'src/app/common/base.component';
import { CategoriesSizesService } from 'src/app/modules/categories/services/categories-sizes.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ICategorySize } from 'src/app/interfaces/category-size.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsSizesService } from '../../services/products-sizes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-sizes-form',
  templateUrl: './products-sizes-form.component.html'
})
export class ProductsSizesFormComponent extends Base implements OnInit, OnDestroy {

  public idProduct: number;
  public idCategory: number;

  public sizes: ICategorySize[];

  constructor(
    private formBuilder: FormBuilder,
    private categoriesSizesService: CategoriesSizesService,
    private sizeService: ProductsSizesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    super();

    const fields = {
      id_size: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
  }

  public ngOnInit() {
    this.busy();
    this.subscriptions.push(this.categoriesSizesService.sizes
      .subscribe((sizes) => {
        if (sizes) {
          this.sizes = sizes;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.categoriesSizesService.list(this.idCategory);
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();
    data.append('id_product', this.idProduct as any);

    this.subscriptions.push(this.create(data));
  }

  private create(data: FormData) {

    this.busyAdd();
    return this.sizeService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Mărime adăugată!', 'Salvat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eroare nu a putut fi adaugată!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }
}
