import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersViewComponent } from './users-view/users-view.component';
import { UsersFormComponent } from './users-form/users-form.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersComponent } from './users.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from 'src/app/layout/layout.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    UsersViewComponent,
    UsersFormComponent,
    UsersListComponent,
    UsersComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
    LayoutModule
  ],
  exports: [
    UsersViewComponent,
    UsersFormComponent,
    UsersListComponent
  ]
})
export class UsersModule {
  constructor() {
    library.add(faUserPlus, faUserEdit);
  }
}
