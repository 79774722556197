import { Pipe, PipeTransform } from '@angular/core';
import { ICategory } from '../interfaces/category.interface';

@Pipe({
    name: 'ordersFilter'
})
export class OrdersFilterPipe implements PipeTransform {

    transform(collection: any[], filterText: string): any {

        if (!collection) {
            return [];
        }

        if (!filterText) {
            return collection;
        }

        filterText = filterText.toLowerCase();

        return collection.filter((row) => {

            // Search in order
            // tslint:disable-next-line:forin
            for (const key in row.order) {
                if (row.order[key]) {
                    const normalize = row.order[key].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    if (normalize.toLowerCase().indexOf(filterText) > -1 || row.order[key].toLowerCase().indexOf(filterText) > -1) {
                        return true;
                    }
                }
            }

            // Search in client
            // tslint:disable-next-line:forin
            for (const key in row.client) {
                if (row.client[key]) {
                    const normalize = row.client[key].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    if (normalize.toLowerCase().indexOf(filterText) > -1 || row.client[key].toLowerCase().indexOf(filterText) > -1) {
                        return true;
                    }
                }
            }
        });
    }

}
