import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public title = 'Comenzi';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService) {

    const routerSub = router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = activatedRoute.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data && data.title) {
          this.title = data.title;
        }
      });
  }

  ngOnInit() {
  }

  editProfile() {
    this.router.navigate(['utilizatori', 'modifica', this.authService.getLoginId()]);
  }
}
