import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html'
})
export class UsersViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
