import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements AfterViewInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngAfterViewInit() {

    setTimeout(() => {
      this.auth.logout();
      this.router.navigate(['/autentificare']);
    }, 3000);
  }

}
