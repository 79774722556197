import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/account/login/login.component';
import { AuthGuard } from './auth/guard/guard';
import { OrdersListComponent } from './modules/orders/orders-list/orders-list.component';
import { MessagesListComponent } from './modules/messages/messages-list/messages-list.component';
import { ClientsListComponent } from './modules/clients/clients-list/clients-list.component';
import { CategoriesListComponent } from './modules/categories/categories-list/categories-list.component';
import { ProductsListComponent } from './modules/products/products-list/products-list.component';
import { UsersListComponent } from './modules/users/users-list/users-list.component';
import { LogoutComponent } from './modules/account/logout/logout.component';
import { CategoriesComponent } from './modules/categories/categories.component';
import { CategoriesFormComponent } from './modules/categories/categories-form/categories-form.component';
import { ProductsComponent } from './modules/products/products.component';
import { ProductsFormComponent } from './modules/products/products-form/products-form.component';
import { ClientsComponent } from './modules/clients/clients.component';
import { MessagesComponent } from './modules/messages/messages.component';
import { OrdersComponent } from './modules/orders/orders.component';
import { UsersComponent } from './modules/users/users.component';
import { UsersFormComponent } from './modules/users/users-form/users-form.component';
import { ClientsFormComponent } from './modules/clients/clients-form/clients-form.component';
import { OrdersFormComponent } from './modules/orders/orders-form/orders-form.component';
import { MessagesViewComponent } from './modules/messages/messages-view/messages-view.component';
import { BlogComponent} from './modules/blog/blog.component';
import { BlogListComponent} from './modules/blog/blog-list/blog-list.component';
import { BlogFormComponent } from './modules/blog/blog-form/blog-form.component';
import { ArticleCategoriesComponent } from './modules/article-categories/article-categories.component';
import { ArticleCategoriesListComponent } from './modules/article-categories/article-categories-list/article-categories-list.component';
import { ArticleCategoriesFormComponent } from './modules/article-categories/article-categories-form/article-categories-form.component';
import { Roles } from 'src/app/configs/roles';

const routes: Routes = [
{
    path: 'blog',
    component: BlogComponent,
    data: {
      permission: [Roles.ADMIN]
    },
    canActivate: [AuthGuard],
    children: [
      {
        path:'',
        component: BlogListComponent
      },
      {
        path: 'adauga',
        component: BlogFormComponent
      },
      {
        path: 'modifica/:id',
        component: BlogFormComponent
      }
    ]
  },

  {
    path:'article_categories',
    component: ArticleCategoriesComponent,
    data: {
      permission: [Roles.ADMIN]
    },
    canActivate: [AuthGuard],
    children: [
      {
      path:'',
      component:ArticleCategoriesListComponent
      },
      {
        path: 'modifica/:id',
        component: ArticleCategoriesFormComponent
      },
      {
        path: 'adauga',
        component: ArticleCategoriesFormComponent
      },
    ]

  },
  {
    path: 'comenzi',
    component: OrdersComponent,
    data: {
      permission: null
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OrdersListComponent
      },
      {
        path: 'adauga',
        component: OrdersFormComponent
      },
      {
        path: 'modifica/:id',
        component: OrdersFormComponent
      }]
  },
  {
    path: 'mesaje',
    component: MessagesComponent,
    data: {
      permission: null
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MessagesListComponent
      },
      {
        path: 'vizualizare/:id',
        component: MessagesViewComponent
      }]
  },
  {
    path: 'clienti',
    component: ClientsComponent,
    data: {
      permission: null
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ClientsListComponent
      },
      {
        path: 'adauga',
        component: ClientsFormComponent
      },
      {
        path: 'modifica/:id',
        component: ClientsFormComponent
      }]
  },
  {
    path: 'categorii',
    component: CategoriesComponent,
    data: {
      permission: [Roles.ADMIN, Roles.MANAGER]
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CategoriesListComponent
      },
      {
        path: 'adauga',
        component: CategoriesFormComponent
      },
      {
        path: 'modifica/:id',
        component: CategoriesFormComponent
      }
    ]
  },
  {
    path: 'produse',
    component: ProductsComponent,
    data: {
      permission: null
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductsListComponent
      },
      {
        path: 'adauga',
        component: ProductsFormComponent
      },
      {
        path: 'modifica/:id',
        component: ProductsFormComponent
      }
    ]
  },
  {
    path: 'utilizatori',
    component: UsersComponent,
    data: {
      permission: [Roles.ADMIN, Roles.MANAGER]
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UsersListComponent
      },
      {
        path: 'adauga',
        component: UsersFormComponent
      },
      {
        path: 'modifica/:id',
        component: UsersFormComponent
      }]
  },
  {
    path: 'iesire-cont',
    component: LogoutComponent
  },
  {
    path: 'autentificare',
    component: LoginComponent
  },
  { path: '**', redirectTo: 'comenzi' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
