import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ICategorySize } from 'src/app/interfaces/category-size.interface';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoriesSizesService {

  public sizesSubject: BehaviorSubject<ICategorySize[]>;
  public sizes: Observable<ICategorySize[]>;

  public cache = {
    sizes: {}
  };

  constructor(private http: HttpClient) {
    this.sizesSubject = new BehaviorSubject<ICategorySize[]>(null);
    this.sizes = this.sizesSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/categories/sizes/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/categories/sizes/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/categories/sizes/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/categories/sizes/delete/${id}`);
  }

  public list(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.sizes && this.cache.sizes[id]) {
      this.sizesSubject.next(this.cache.sizes[id]);

      return of(this.cache.sizes);
    }

    return this.http.get(`${environment.apiUrl}/categories/sizes/all/${id}`)
      .pipe(map((response: IResponse) => {
        this.cache.sizes[id] = response.data;
        this.sizesSubject.next(response.data || []);
      })).subscribe();
  }

  public empty() {
    this.sizesSubject.next([]);
  }
}
