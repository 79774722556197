import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IClient } from 'src/app/interfaces/client.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {


  public clientsSubject: BehaviorSubject<IClient[]>;
  public clients: Observable<IClient[]>;

  public cache = {
    clients: []
  };

  constructor(private http: HttpClient) {
    this.clientsSubject = new BehaviorSubject<IClient[]>(null);
    this.clients = this.clientsSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/clients/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/clients/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/clients/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/clients/delete/${id}`);
  }

  public unique(data: FormData, column: string, id?) {
    if (id) {
      return this.http.post(`${environment.apiUrl}/clients/unique/update/${column}/${id}`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/clients/unique/insert/${column}`, data);
    }
  }


  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.clients && this.cache.clients.length > 0) {
      this.clientsSubject.next(this.cache.clients);

      return of(this.cache.clients);
    }

    return this.http.get(`${environment.apiUrl}/clients/all`)
      .pipe(map((response: IResponse) => {
        this.cache.clients = response.data;
        this.clientsSubject.next(response.data || []);
      })).subscribe();
  }

  public empty() {
    this.clientsSubject.next([]);
  }

  public purge() {
    this.cache.clients = null;
    this.empty();
    this.list(true);
  }
}
