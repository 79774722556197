import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Base } from 'src/app/common/base.component';
import { Component, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';
import { MessagesService } from '../services/messages.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-messages-view',
  templateUrl: './messages-view.component.html',
  styleUrls: ['./messages-view.component.scss']
})
export class MessagesViewComponent extends Base implements OnDestroy {

  // UI Binds
  public message = {
    subject: '',
    message: '',
    first_name: '',
    last_name: '',
    sent_date: ''
  };

  constructor(
    private messagesService: MessagesService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) {

    super();

    // Fetch message data
    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {

        // Same route no need to continue
        if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

        // fetch route id
        this.id = this.route.snapshot.params.id;

        //  set edit mode
        if (this.id) {

          this.subscriptions.push(this.messagesService
            .read(this.id)
            .subscribe((response: IResponse) => {

              if (!response.success) {
                this.toastr.error('Produsul nu există!', 'Eroare!');
                this.router.navigate(['404']);
                return;
              }

              this.message = response.data;

              // Set seen = 1
              this.subscriptions.push(this.messagesService.seen(this.id).subscribe(this.noop, this.onError.bind(this)));

              this.idle();
            }, this.onError.bind(this)));
        }
      }));
  }

  public delete() {
    this.busy('Se șterge');

    return this.messagesService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.messagesService.purge();
          this.toastr.success('Mesajul a fost ștears!', 'Șters!');
          this.router.navigate(['mesaje']);
        } else {
          this.toastr.error('Mesajul nu a fost ștears!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }
}
