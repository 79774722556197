import { Base } from 'src/app/common/base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent extends Base implements OnInit, OnDestroy {

  public messages: any[] = [];

  constructor(private messagesService: MessagesService) {
    super();
  }

  public ngOnInit() {
    this.getMessages();
  }

  public ngOnDestroy() {
    this.messages = [];
    this.messagesService.empty();
    this.unsubscribe();
  }

  public refresh() {
    this.busy('Se sincronizează');
    this.messagesService.list(true);
  }

  private getMessages() {
    this.busy('Se încarcă');

    this.subscriptions.push(this.messagesService.messages
      .subscribe((messages) => {
        if (messages !== null) {
          this.messages = messages;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.messagesService.list(true);
  }
}
