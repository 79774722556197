import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountModule } from './modules/account/account.module';
import { CategoriesModule } from './modules/categories/categories.module';
import { ClientsModule } from './modules/clients/clients.module';
import { LayoutModule } from './layout/layout.module';
import { OrdersModule } from './modules/orders/orders.module';
import { ProductsModule } from './modules/products/products.module';
import { UsersModule } from './modules/users/users.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { MessagesModule } from './modules/messages/messages.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BlogModule } from './modules/blog/blog.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ArticleCategoriesModule } from './modules/article-categories/article-categories.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    AccountModule,
    CategoriesModule,
    ClientsModule,
    MessagesModule,
    LayoutModule,
    OrdersModule,
    ProductsModule,
    UsersModule,
    NgbModule,
    BrowserAnimationsModule,
    ArticleCategoriesModule,
    EditorModule,
    BlogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
