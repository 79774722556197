import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ICharacteristic } from 'src/app/interfaces/characteristic.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IResponse } from 'src/app/interfaces/response.interface';
import { CategoriesCharacteristicsService } from '../../services/categories-characteristics.service';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-characteristics-form',
  templateUrl: './characteristics-form.component.html',
})
export class CharacteristicsFormComponent extends Base implements OnDestroy {

  public category: number;

  constructor(
    private formBuilder: FormBuilder,
    private characteristicsService: CategoriesCharacteristicsService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    super();

    const fields: ICharacteristic = {
      characteristic_ro: ['', Validators.required],
      characteristic_en: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);

    this.idle();
  }

  public edit(characteristic: ICharacteristic) {
    this.id = characteristic.id_characteristic;
    this.category = characteristic.id_category;
    this.f.characteristic_ro.setValue(characteristic.characteristic_ro);
    this.f.characteristic_en.setValue(characteristic.characteristic_en);
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();
    data.append('id_category', this.category as any);

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  private create(data: FormData) {
    this.busySave();

    return this.characteristicsService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Caracteristic salvat!', 'Salvat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eroare nu a putut fi salvat!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();

    return this.characteristicsService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Caracteristic actualizat!', 'Modificat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eraore nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  public delete() {
    this.busyDelete();

    return this.characteristicsService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Characteristic șters!', 'Șters!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Characteristic nu a fost sters!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }
}
