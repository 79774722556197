import { Component } from '@angular/core';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TinkAdmin';

  public loggedIn = false;
  constructor(private auth: AuthService) {
    this.auth.isLoggedIn.subscribe(x => this.loggedIn = x);
  }
}
