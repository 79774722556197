import { Injectable } from '@angular/core';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private toaster: ToastrService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.method !== 'OPTIONS') {
            req = req.clone({
                withCredentials: true
            });

            return next.handle(req)
                .pipe(catchError((error, caught) => {
                    this.handleAuthError(error);
                    return of(error);
                }) as any);
        }
        return next.handle(req);
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err.status === 401) {
            // navigate /delete cookies or whatever
            this.router.navigate(['/autentificare']);
            this.toaster.error('Sesiune expirată', 'Neautorizat');

            return of(err.message);
        } else {
            if (err.status === 0 && err.statusText === 'Unknown Error') {
                this.toaster.error('Serviciu indisponibil, vă rugăm încercați mai târziu.', 'Error');
            } else {
                this.toaster.error(err.message, 'Error');
            }
        }
        throw err;
    }
}
