import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BlogComponent} from './blog.component';
import { BlogListComponent } from './blog-list/blog-list.component';
// import { AppRoutingModule } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from 'src/app/layout/layout.module';
import { BlogFormComponent } from './blog-form/blog-form.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { MatOption} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
@NgModule({
  declarations: [BlogComponent, BlogListComponent, BlogFormComponent],
  imports: [
    MatSelectModule,
    EditorModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    // AppRoutingModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LayoutModule,

  ],
  
})
export class BlogModule { }
