import { Component, OnInit, OnDestroy } from '@angular/core';
import { articles } from 'src/app/interfaces/articles.interface';
import { BlogService } from '../service/blog.service';
import { Subscription } from 'rxjs';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
})
export class BlogListComponent extends Base implements OnInit, OnDestroy {
  public articles: articles[]=[];
  constructor(private blogService: BlogService) { super();}

  ngOnInit() {
    this.getBlog();
  }

  public ngOnDestroy() {
    this.articles = [];
    this.blogService.empty();
    this.unsubscribe();
  }

  public refresh() {
    this.busySync();
    this.blogService.list(true);
  }

  private getBlog() {
    this.busy();

    this.subscriptions.push(this.blogService.articles.subscribe((articles) => {
      if (articles !== null) {
        this.articles = articles;
        this.idle();
      }
    }, this.onError.bind(this)));

    this.subscriptions.push(this.blogService.list() as Subscription);
  }
}
