import { Base } from 'src/app/common/base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProduct } from 'src/app/interfaces/product.interface';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html'
})
export class ProductsListComponent extends Base implements OnInit, OnDestroy {

  public products: IProduct[] = [];

  constructor(private productsService: ProductsService) {
    super();
  }

  public ngOnInit() {
    this.getProducts();
  }

  public ngOnDestroy() {
    this.products = [];
    this.productsService.empty();
    this.unsubscribe();
  }

  public refresh() {
    this.busySync();
    this.productsService.list(true);
  }

  private getProducts() {
    this.busy();

    this.subscriptions.push(
      this.productsService.products.subscribe((product) => {
      if (product !== null) {
        this.products = product;
        this.idle();
      }
    }, this.onError.bind(this)));

    this.productsService.list();
  }

}
