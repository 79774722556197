export const MainGroups = [
    {
        ID: 'femei',
        LABEL: 'Femei'
    }, {
        ID: 'barbati',
        LABEL: 'Bărbati'
    }, {
        ID: 'copii',
        LABEL: 'Copii'
    }
];
