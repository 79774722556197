import { BytelabsColorSelectorModule } from '@bytelabsco/ngx-color-selector';
import { CategoriesFilterPipe } from 'src/app/pipes/categories-filter.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { environment } from 'src/environments/environment';
import {
  faBoxes,
  faCameraRetro,
  faPalette,
  faPencilAlt,
  faPlus,
  faSync,
  faTag,
  faTags,
  faTimes,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from 'src/app/layout/layout.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ProductsColorsFormComponent } from './products-form/products-colors-form/products-colors-form.component';
import { ProductsColorsListComponent } from './products-form/products-colors-list/products-colors-list.component';
import { ProductsComponent } from './products.component';
import { ProductsFormComponent } from './products-form/products-form.component';
import { ProductsImagesFormComponent } from './products-form/products-images-form/products-images-form.component';
import { ProductsImagesListComponent } from './products-form/products-images-list/products-images-list.component';
import { ProductsImagesViewComponent } from './products-form/products-images-view/products-images-view.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductsPropertiesFormComponent } from './products-form/products-properties-form/products-properties-form.component';
import { ProductsPropertiesListComponent } from './products-form/products-properties-list/products-properties-list.component';
import { ProductsSizesFormComponent } from './products-form/products-sizes-form/products-sizes-form.component';
import { ProductsSizesListComponent } from './products-form/products-sizes-list/products-sizes-list.component';
import { ProductsStocksFormComponent } from './products-form/products-stocks-form/products-stocks-form.component';
import { RouterModule } from '@angular/router';
import { NgxCurrencyModule } from 'ngx-currency';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: environment.apiUrl,
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: false
};

@NgModule({
  declarations: [
    ProductsFormComponent,
    ProductsListComponent,
    ProductsComponent,
    ProductsImagesFormComponent,
    ProductsImagesListComponent,
    ProductsSizesListComponent,
    ProductsPropertiesListComponent,
    ProductsPropertiesFormComponent,
    ProductsImagesViewComponent,
    ProductsColorsListComponent,
    ProductsColorsFormComponent,
    ProductsSizesFormComponent,
    ProductsStocksFormComponent,
    CategoriesFilterPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    NgbModule,
    DropzoneModule,
    ColorPickerModule,
    BytelabsColorSelectorModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  exports: [
    ProductsFormComponent,
    ProductsListComponent
  ],
  entryComponents: [
    ProductsImagesFormComponent,
    ProductsImagesViewComponent,
    ProductsSizesFormComponent,
    ProductsColorsFormComponent,
    ProductsPropertiesFormComponent
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class ProductsModule {
  constructor() {
    library.add(faPlus, faTimes, faPencilAlt, faSync, faTags, faTag, faUpload, faCameraRetro, faPalette, faBoxes);
  }
}
