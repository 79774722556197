import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICategory } from 'src/app/interfaces/category.interface';
import { Subscription } from 'rxjs';
import { CategoriesService } from '../services/categories.service';
import { MainGroups } from 'src/app/configs/main-groups';
import { ProductTypes } from 'src/app/configs/product-types';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html'
})
export class CategoriesListComponent extends Base implements OnInit, OnDestroy {

  public categories: ICategory[] = [];

  constructor(private categoriesService: CategoriesService) {
    super();
  }

  public ngOnInit() {
    this.getCategories();
  }

  public ngOnDestroy() {
    this.categories = [];
    this.categoriesService.empty();
    this.unsubscribe();
  }

  public labelMainGroup(groupID) {
    const group = MainGroups.find(g => g.ID === groupID);
    return group ? group.LABEL : 'Toti';
  }

  public labelProductsType(prodID) {
    const type = ProductTypes.find(p => p.ID === prodID);
    return type ? type.LABEL : 'Toate';
  }

  public refresh() {
    this.busySync();
    this.categoriesService.list(true);
  }

  private getCategories() {
    this.busy();

    this.subscriptions.push(this.categoriesService.categories.subscribe((categories) => {
      if (categories !== null) {
        this.categories = categories;
        this.idle();
      }
    }, this.onError.bind(this)));

    this.subscriptions.push(this.categoriesService.list() as Subscription);
  }
}
