import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tableSort'
})
export class TableSortPipe implements PipeTransform {

    transform(collection: any[], column: string, direction: string): any {
        // const rotate: { [key: string]: string } = { asc: 'desc', desc: 'asc', '': 'asc' };
        function compare(v1, v2) { return v1 < v2 ? -1 : v1 > v2 ? 1 : 0; }

        if (!collection) {
            return [];
        }

        if (!column) {
            return collection;
        }

        // direction = rotate[direction];

        return collection.sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }

}
