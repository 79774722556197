import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

    transform(collection: any[], columns: string[], filterText: string): any {

        if (!collection) {
            return [];
        }

        if (!filterText) {
            return collection;
        }

        filterText = filterText.toLowerCase();

        return collection.filter((row) => {
            for (const column of columns) {
                if (row[column]) {
                    const normalize = row[column].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    if (normalize.indexOf(filterText) > -1 || row[column].toLowerCase().indexOf(filterText) > -1) {
                        return true;
                    }
                }
            }
        });
    }

}
