import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ArticleCategoriesService } from '../service/article-categories.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Base } from 'src/app/common/base.component';
import { article_categories } from 'src/app/interfaces/article-categories.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-categories-form',
  templateUrl: './article-categories-form.component.html',
  styleUrls: ['./article-categories-form.component.scss']
})
export class ArticleCategoriesFormComponent extends Base implements OnDestroy {
  private debounceUniqueTimer;
  fileSelected: File = null;
  private copyFileName = '';
  private fileName = 'Choose a file';
  private newFile = '';
  public siteUrl: string;

  constructor(private formBuilder: FormBuilder,
    private articleCategoryService: ArticleCategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) {
    super();
    this.siteUrl = environment.siteUrl;
    this.buildForm();

    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {
        if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

        this.id = this.route.snapshot.params.id;

        if (this.id) {
          this.subscriptions.push(this.articleCategoryService
            .read(this.id)
            .subscribe((response: IResponse) => {

              if (!response.success) {
                this.toastr.error('Categoria nu exista!', 'Eroare!');
                this.router.navigate(['404']);
                return;
              }

              for (const key in response.data) {
                if (this.f.hasOwnProperty(key) && response.data.hasOwnProperty(key)) {

                  if (key == "picture") {
                    this.copyFileName = response.data[key];
                    this.fileName = response.data[key];
                    continue;

                  }
                  this.f[key].setValue(response.data[key]);
                }
              }

              this.idle();
            }, this.onError.bind(this)));

        } else {
          this.idle();
        }
      }));
  }

  ngOnInit() {
  }

  private buildForm() {
    // must match database collumns
    var fields: article_categories = <any>{
      'category_ro': ['', Validators.required],
      'category_en': ['', Validators.required],
      'category_url_ro': ['', Validators.required],
      'category_url_en': ['', Validators.required],
      'picture': ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
    this.f.category_url_en.setAsyncValidators(this.isUnique.bind(this));
    this.f.category_url_ro.setAsyncValidators(this.isUnique.bind(this));
  }

  private async isUnique(control: AbstractControl): Promise<any> {

    clearTimeout(this.debounceUniqueTimer);

    return new Promise((resolve, reject) => {

      this.debounceUniqueTimer = setTimeout(() => {

        const column = this.getControlName(control);
        const data = new FormData();
        data.append(column, control.value);

        this.articleCategoryService.unique(data, column, this.id)
          .subscribe((response: IResponse) => {

            if (response.success) {
              resolve(null);
            } else {
              resolve({ notUnique: true });
            }
          },
            (error) => {
              resolve({ notUnique: true });
            });
      }, 600);
    });
  }

  public submit() {
    this.submitted = true;

    if (this.form.invalid && this.isEdit() == false) {
      this.toastr.error('Nu ai completat formularul corect!', 'Eroare!');
      return;
    }

    let data = this.getFormData();
    data.append("userfile", this.fileSelected);

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }



  private update(data) {

    this.busyUpdate();

    if (this.copyFileName != this.fileName) {

      let path = data.get("picture");
      let arraySplit = path.split("\\");
      let fileName = arraySplit[arraySplit.length - 1];
      let arraySplitPoint = fileName.split(".");
      fileName = arraySplitPoint[0] + '_' + (this.id) + '.' + arraySplitPoint[1];
      data.set("picture", fileName);

      const observerDelete = this.articleCategoryService.deletePicture(this.copyFileName);

      this.newFile = fileName;

      return observerDelete.pipe(
        switchMap(value => {
          return this.articleCategoryService.update(data, this.id);
        },
      ), catchError((err) => { 
        debugger;
        this.toastr.error('Eroare', err.error.data);
        //return this.articleCategoryService.update(data, this.id); 
        return throwError(err); 
      })).subscribe(
        (response: IResponse) => {

          if (response.success) {
            this.articleCategoryService.purge();
            this.toastr.success('Categoria a fost actualizata!', 'Modificat!');
            this.router.navigate(['article_categories', 'modifica', this.id]);
          } else {
            this.toastr.error('Categoria nu a fost actualizata!', 'Eroare');
          }
          this.idle();
        },
        this.onError.bind(this),
        // error=>console.log("eroare"),
        () => { 
          this.copyFileName = this.newFile; 
        }
      )

    }
    else {

      data.delete("picture");

      return this.articleCategoryService
        .update(data, this.id)
        .subscribe((response: IResponse) => {
          debugger;
          if (response.success) {
            this.articleCategoryService.purge();
            this.toastr.success('Categoria a fost actualizata!', 'Modificat!');
            this.router.navigate(['article_categories', 'modifica', this.id]);

          } else {
            this.toastr.error('Categoria nu a putut fi actualizata!', 'Eroare');
          }

          this.idle();
        }, this.onError.bind(this)
        );
    }
  }

  public get_last_id() {
    const observable = this.articleCategoryService
      .last_id();
    observable.subscribe((response: IResponse) => {
      if (response.success) {
        return response.data;
      } else {
        return "error";
      }
    });
  }

  onFileSelected(event) {
    this.fileSelected = <File>event.target.files[0];
    var file = event.target.files[0].name;
    this.fileName = file;
    console.log(event.target.files[0].name);
  }

  private create(data) {

    const observableId = this.articleCategoryService.last_id();
    return observableId.pipe(switchMap(
      value => {
        let path = data.get("picture");
        let arraySplit = path.split("\\");
        let fileName = arraySplit[arraySplit.length - 1];
        let arraySplitPoint = fileName.split(".");
        fileName = arraySplitPoint[0] + '_' + (parseInt(value['data']) + 1) + '.' + arraySplitPoint[1];

        data.set("picture", fileName);

        this.newFile = fileName;
        return this.articleCategoryService.create(data);
      }
    )).subscribe(
      (response: IResponse) => {
        if (response.success) {

          this.articleCategoryService.purge();
          this.toastr.success('Categoria a fost creata cu success.', 'Succes');
          this.router.navigate(['article_categories', 'modifica', response.data.id]);

        } else {
          this.toastr.error('Categoria nu a putut fi creata.', 'Eroare');
        }
        this.idle();
      }, this.onError.bind(this),
    );
  }

  public delete() {

    this.busyDelete();

    this.subscriptions.push(
      this.articleCategoryService
        .delete(this.id)
        .subscribe((response: IResponse) => {
          if (response.success) {
            this.articleCategoryService.purge();
            this.toastr.success('Categoria a fost ștearsă cu succes.', 'Succes');
            this.router.navigate(['article_categories']);
          } else {
            this.toastr.error('Categoria nu a fost stearsă.', 'Eroare');
          }

          this.idle();
        }, this.onError.bind(this)));
  }

}
