import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IPicture } from 'src/app/interfaces/images.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  public imagesSubject: BehaviorSubject<IPicture[]>;
  public images: Observable<IPicture[]>;

  public cache = {
    images: []
  };

  constructor(private http: HttpClient) {
    this.imagesSubject = new BehaviorSubject<IPicture[]>(null);
    this.images = this.imagesSubject.asObservable();
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/products/pictures/delete/${id}`);
  }

  public list(idProduct: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.images && this.cache.images[idProduct]) {
      this.imagesSubject.next(this.cache.images[idProduct]);
      return of(this.cache.images);
    }

    return this.http.get(`${environment.apiUrl}/products/pictures/all/${idProduct}`)
      .pipe(map((response: IResponse) => {
        this.cache.images[idProduct] = response.data;
        this.imagesSubject.next(response.data || []);
      })).subscribe();
  }

  public empty() {
    this.imagesSubject.next([]);
  }
}
