import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';
import { ClientsService } from '../services/clients.service';
import { IClient } from 'src/app/interfaces/client.interface';
import { Languages } from 'src/app/configs/language';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-clients-form',
  templateUrl: './clients-form.component.html'
})
export class ClientsFormComponent extends Base implements OnDestroy {

  private debounceUniqueTimer;
  public languages = Languages;

  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    super();

    this.buildForm();

    this.subscriptions.push(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((val) => {

          // same route no need to continue
          if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

          this.id = this.route.snapshot.params.id;

          // Edit mode
          if (this.id) {
            this.subscriptions.push(this.clientsService
              .read(this.id)
              .subscribe((response: IResponse) => {

                if (!response.success) {
                  this.toastr.error('Clientul nu exista!', 'Eroare!');
                  this.router.navigate(['404']);
                  return;
                }

                for (const key in response.data) {
                  // TODO remove password from server before sending to client
                  if (this.f.hasOwnProperty(key) && response.data.hasOwnProperty(key) && key !== 'password') {
                    this.f[key].setValue(response.data[key]);
                  }
                }

                // Edit mode we validate match but not require
                this.f.repassword.setValidators([this.matchPasswords.bind(this)]);

                this.idle();
              }, this.onError.bind(this)));

          } else {

            // Create mode we require passwords
            this.f.password.setValidators([Validators.required, Validators.minLength(6)]);
            this.f.repassword.setValidators([Validators.required]);

            this.idle();
          }
        }));
  }

  private buildForm() {

    // must match database columns
    const fields: IClient = {
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      language: [Languages[0].ID, Validators.required],
      valid: [0, Validators.required],
      delivery_address: ['', Validators.required],
      billing_address: [''],
      password: ['', Validators.minLength(6)],
      repassword: ['']
    };

    // Set match passwords
    this.form = this.formBuilder.group(fields, {
      validators: this.matchPasswords
    });

    // email should be unique
    this.f.email.setAsyncValidators(this.isUnique.bind(this));
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  public delete() {
    this.busy('Se șterge');

    this.subscriptions.push(this.clientsService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.clientsService.purge();
          this.toastr.success('Clientul a fost ștears!', 'Șters!');
          this.router.navigate(['clienti']);
        } else {
          this.toastr.error('Clientul nu a fost stears!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this)));
  }

  private create(data: FormData) {
    this.busy('Se salvează');

    return this.clientsService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.clientsService.purge();
          this.toastr.success('Clientul a fost salvat!', 'Salvat!');
          this.router.navigate(['clienti', 'modifica', response.data.id]);
        } else {
          this.toastr.error('Clientul nu a putut fi salvat!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busy('Se actualizează');

    return this.clientsService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.clientsService.purge();
          this.toastr.success('Clientul a fost actualizat!', 'Modificat!');
          this.router.navigate(['clienti', 'modifica', this.id]);

        } else {
          this.toastr.error('Clientul nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private matchPasswords(group: FormGroup) {
    if (group.controls) {
      const password = group.controls.password.value;
      const passwordConfirmation = group.controls.repassword.value;

      return password === passwordConfirmation ? null : { noMatch: true };
    }

    return null;
  }

  private async isUnique(control: AbstractControl): Promise<any> {
    clearTimeout(this.debounceUniqueTimer);

    return new Promise((resolve, reject) => {
      this.debounceUniqueTimer = setTimeout(() => {
        const column = this.getControlName(control);
        const data = new FormData();
        data.append(column, control.value);

        this.clientsService.unique(data, column, this.id)
          .subscribe((response: IResponse) => {
            if (response.success) {
              resolve(null);
            } else {
              resolve({ notUnique: true });
            }
          },
            (error) => {
              resolve({ notUnique: true });
            });
      }, 600);
    });
  }

}
