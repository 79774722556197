import { Component, OnInit,  Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent {

  constructor() { }

  searchTerm;
  
  @Output() 
  change = new EventEmitter();

  search(term){
      this.change.emit(term);
  }

}
