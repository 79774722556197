import { Pipe, PipeTransform } from '@angular/core';
import { ICategory } from '../interfaces/category.interface';

@Pipe({
  name: 'categoriesFilter'
})
export class CategoriesFilterPipe implements PipeTransform {

  transform(categories: ICategory[], filterText: string): any {

    if (!categories) {
      return [];
    }

    return categories.filter((category) => {
      return ((category.category_name_ro as string).search(new RegExp(filterText, 'i')) > -1);
    });
  }

}
