export const OrderStatus = [
    {
        ID: 0,
        LABEL: 'Nouă'
    },
    {
        ID: 1,
        LABEL: 'Procesată'
    },
    {
        ID: 2,
        LABEL: 'Finalizată'
    },
    {
        ID: -1,
        LABEL: 'Anulată'
    },
];
