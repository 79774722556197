import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AccountService } from '../services/account.service';
import { first } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Status } from 'src/app/configs/status';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public loading = false;
  public submitted = false;

  private loginSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private accountService: AccountService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnDestroy() {
    return this.loginSub && this.loginSub.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  submit() {

    // Enable ui validation feedback
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    this.loading = true;
    this.loginSub = this.accountService.login(this.f.email.value, this.f.password.value)
      .subscribe((response: IResponse) => {
        if (response.success) {

          this.router.navigate([this.auth.returnUrl || 'comenzi']);
          this.auth.returnUrl = null;
        } else {
          this.toaster.error('Email sau parolă incorectă.', 'Neautorizat!');
        }

        this.loading = false;
      }, (error) => this.loading = false);
  }
}
