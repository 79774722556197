import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { articles } from 'src/app/interfaces/articles.interface';
import { Base } from 'src/app/common/base.component';
import { BlogService } from '../service/blog.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from 'src/app/interfaces/response.interface';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-blog-form',
  templateUrl: './blog-form.component.html',
})
export class BlogFormComponent extends Base implements OnDestroy {
  fileName = 'Choose file';
  private copyFileName = '';
  fileSelected: File = null;
  descriptionRo = '';
  descriptionEn = '';
  private newFile = '';
  private debounceUniqueTimer;
  blogCategories=[];
  public siteUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private blogService: BlogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) {
    super();

    this.siteUrl = environment.siteUrl;
    this.buildForm();

    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {
        if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

        this.id = this.route.snapshot.params.id;

        if (this.id) {
          this.subscriptions.push(this.blogService
            .read(this.id)
            .subscribe((response: IResponse) => {

              if (!response.success) {
                this.toastr.error('Categoria nu exista!', 'Eroare!');
                this.router.navigate(['404']);
                return;
              }

              for (const key in response.data) {
                if (this.f.hasOwnProperty(key) && response.data.hasOwnProperty(key)) {
                  if (key == "banner") {
                    this.copyFileName = response.data[key];
                    this.fileName = response.data[key];
                    continue;
                  }

                  this.f[key].setValue(response.data[key]);
                }  
              }

              this.idle();
            }, this.onError.bind(this)));

        } else {
          this.idle();
        }
      }));

      this.subscriptions.push(
        this.blogService.get_category_articles()
          .subscribe((response:IResponse)=>{

            if(response.success){
              this.blogCategories = response.data;
            }

      }));
  }

  ngOnInit() {
  }

  private async isUnique(control: AbstractControl): Promise<any> {
    clearTimeout(this.debounceUniqueTimer);

    return new Promise((resolve, reject) => {
      this.debounceUniqueTimer = setTimeout(() => {
        const column = this.getControlName(control);
        const data = new FormData();
        data.append(column, control.value);
        this.blogService.unique(data, column, this.id)
          .subscribe((response: IResponse) => {
            if (response.success) {
              resolve(null);
            } else {
              resolve({ notUnique: true });
            }
          },
            (error) => {
              resolve({ notUnique: true });
            });
      }, 600);
    });
  }

  private buildForm() {
    // must match database collumns
    var fields: articles = <any>{
      'title_ro': ['', Validators.required],
      'title_en': ['', Validators.required],
      'short_desc_ro': ['', Validators.required],
      'description_ro': ['', Validators.required],
      'description_en': ['', Validators.required],
      'short_desc_en': ['', Validators.required],
      'url_ro': ['', Validators.required],
      'url_en': ['', Validators.required],
      'meta_title_ro': ['', [Validators.required, Validators.minLength(20)]],
      'meta_title_en': ['', Validators.required],
      'meta_desc_ro': ['', Validators.required],
      'meta_desc_en': ['', Validators.required],
      'keywords_ro': ['', Validators.required],
      'keywords_en': ['', Validators.required],
      'banner': ['', Validators.required],
      'id_category': ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
    this.f.url_en.setAsyncValidators(this.isUnique.bind(this));
    this.f.url_ro.setAsyncValidators(this.isUnique.bind(this));
  }

  onFileSelected(event) {
    this.fileSelected = <File>event.target.files[0];
    var file = event.target.files[0].name;
    this.fileName = file;
    console.log(event.target.files[0].name);
  }

  
  private create(data) {
    const observableId = this.blogService.last_id();
    return observableId.pipe(switchMap(
      value => {
        let path = data.get("banner");
        let arraySplit = path.split("\\");
        let fileName = arraySplit[arraySplit.length - 1];
        let arraySplitPoint = fileName.split(".");
        fileName = arraySplitPoint[0] + '_' + (parseInt(value['data']) + 1) + '.' + arraySplitPoint[1];

        data.set("banner", fileName);

        this.newFile = fileName;
        return this.blogService.create(data);
      }
    )).subscribe(
      (response: IResponse) => {

        if (response.success) {
          this.blogService.purge();
          this.toastr.success('Articolul a fost publicat!', 'Succes');
          this.router.navigate(['blog', 'modifica', response.data.id]);

        } else {
          this.toastr.error('Articolul nu a putut fi publicat.', 'Eroare');
        }
        this.idle();
      }, this.onError.bind(this),
    );
  }


  public submit() {

    this.submitted = true;

    if (this.form.invalid && this.isEdit() == false) {
      this.toastr.error('Nu ai completat formularul corect.', 'Eroare');
      this.blogService.purge();
      console.log(this.blogService.cache.articles);
      return;
    }
    let data = this.getFormData();
    data.append("userfile", this.fileSelected);
    console.log(data.get('id_category'));

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }
 

  private update(data) {

    this.busyUpdate();

    if (this.copyFileName != this.fileName) {

      let path = data.get("banner");
      let arraySplit = path.split("\\");
      let fileName = arraySplit[arraySplit.length - 1];
      let arraySplitPoint = fileName.split(".");
      fileName = arraySplitPoint[0] + '_' + (this.id) + '.' + arraySplitPoint[1];
      data.set("banner", fileName);

      const observerDelete = this.blogService.deletePicture(this.copyFileName);

      this.newFile = fileName;

      return observerDelete.pipe(switchMap(
        value => {
          return this.blogService.update(data, this.id);
        }),
        catchError((err) => { 
          console.log('err'); 
          return this.blogService.update(data, this.id); }))
            .subscribe(
              (response: IResponse) => {
                if (response.success) {
                  this.blogService.purge();
                  this.toastr.success('Articolul a fost actualizat.', 'Succes');
                  this.router.navigate(['blog', 'modifica', this.id]);
                } else {
                  this.toastr.error('Articolul nu a putut fi actualizat.', 'Eroare');
                }
                this.idle();
              },
              this.onError.bind(this),
              // error=>console.log("eroare"),
              () => { 
                this.copyFileName = this.newFile; 
              }
            )

    }
    else {

      data.delete("picture");

      return this.blogService
        .update(data, this.id)
        .subscribe((response: IResponse) => {
          if (response.success) {
            this.blogService.purge();
            this.toastr.success('Articolul a fost actualizat.', 'Succes');
            this.router.navigate(['blog', 'modifica', this.id]);

          } else {
            this.toastr.error('Articolul nu a putut fi actualizat!', 'Eroare');
          }

          this.idle();
        }, this.onError.bind(this)
        );
    }
  }

  public delete() {

    this.busyDelete();

    this.subscriptions.push(
      this.blogService
        .delete(this.id)
        .subscribe((response: IResponse) => {
          if (response.success) {
            this.blogService.purge();
            this.toastr.success('Articolul a fost ștears.', 'Succes');
            this.router.navigate(['blog']);
          } else {
            this.toastr.error('Articolul nu a fost stears.', 'Eroare');
          }

          this.idle();

        }, this.onError.bind(this)));
  }


}
