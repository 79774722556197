import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/interfaces/response.interface';
import { map } from 'rxjs/operators';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { IProductSize } from 'src/app/interfaces/product-size.interface';
import { HttpClient } from '@angular/common/http';
import { IUser } from 'src/app/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {


  public usersSubject: BehaviorSubject<IUser[]>;
  public users: Observable<IUser[]>;

  public cache = {
    users: []
  };

  constructor(private http: HttpClient) {
    this.usersSubject = new BehaviorSubject<IUser[]>(null);
    this.users = this.usersSubject.asObservable();
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/users/create`, data);
  }

  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/users/update/${id}`, data);
  }

  public read(id: number) {
    return this.http.get(`${environment.apiUrl}/users/read/${id}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/users/delete/${id}`);
  }

  public unique(data: FormData, column: string, id?) {
    if (id) {
      return this.http.post(`${environment.apiUrl}/users/unique/update/${column}/${id}`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/users/unique/insert/${column}`, data);
    }
  }


  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.users && this.cache.users.length > 0) {
      this.usersSubject.next(this.cache.users);

      return of(this.cache.users);
    }

    return this.http.get(`${environment.apiUrl}/users/all`)
      .pipe(map((response: IResponse) => {
        this.cache.users = response.data;
        this.usersSubject.next(response.data || []);
      })).subscribe();
  }

  public empty() {
    this.usersSubject.next([]);
  }

  public purge() {
    this.cache.users = null;
    this.empty();
    this.list(true);
  }
}
