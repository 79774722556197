import { Component, OnInit, Input } from '@angular/core';
import { ProductsStockService } from '../../services/products-stocks.service';
import { Subscription } from 'rxjs';
import { IProductStock } from 'src/app/interfaces/product-stock.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { ToastrService } from 'ngx-toastr';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-products-stocks-form',
  templateUrl: './products-stocks-form.component.html',
  styleUrls: ['./products-stocks-form.component.scss']
})
export class ProductsStocksFormComponent extends Base implements OnInit {

  @Input() product;
  public stocks: IProductStock[] = [];

  constructor(
    private stockService: ProductsStockService,
    private toastr: ToastrService) {
    super();
  }

  ngOnInit() {
    
    this.subscriptions.push(
      this.stockService.stocks.subscribe((stocks: IProductStock[]) => {
        if (stocks !== null) {
          this.stocks = stocks;
          this.idle();
        }
      }, this.onError.bind(this)));

   this.stockService.list(this.product);
  }

  public submit() {

    this.busySave();
    const data = new FormData();

    const prepData = this.stocks.map((s) => {
      const stock: any = {
        id_product: this.product,
        id_product_color: s.id_product_color,
        id_product_size: s.id_product_size,
        quantity: s.quantity
      };


      if (s.id_product_stock !== 0) {
        stock.id_product_stock = s.id_product_stock;
      }

      return stock;
    });

    data.append('stock', JSON.stringify(prepData));

    this.stockService
      .update(data, this.product)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Stoc actualizat', 'Actualizat');

          this.stockService.list(this.product);

        } else {
          this.toastr.error('Stocul nu a fost actualizat', 'Eroare');
        }
        this.idle();
      }, this.onError.bind(this));
  }
}
