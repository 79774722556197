import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Base } from 'src/app/common/base.component';
import { Component, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { IResponse } from 'src/app/interfaces/response.interface';
import { IUser } from 'src/app/interfaces/user.interface';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html'
})
export class UsersFormComponent extends Base implements OnDestroy {
  private debounceUniqueTimer;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    super();

    this.buildForm();

    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {
        if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

        this.id = this.route.snapshot.params.id;

        if (this.id) {
          this.subscriptions.push(this.usersService
            .read(this.id)
            .subscribe((response: IResponse) => {

              if (!response.success) {
                this.toastr.error('Utilizatorul nu exista!', 'Eroare!');
                this.router.navigate(['404']);
                return;
              }

              for (const key in response.data) {
                if (this.f.hasOwnProperty(key) && response.data.hasOwnProperty(key) && key !== 'password') {
                  this.f[key].setValue(response.data[key]);
                }
              }

              this.f.repassword.setValidators([this.matchPasswords.bind(this)]);

              this.idle();
            }, this.onError.bind(this)));

        } else {
          this.f.password.setValidators([Validators.required, Validators.minLength(6)]);
          this.f.repassword.setValidators([Validators.required]);
          this.idle();
        }
      }, this.onError.bind(this)));

  }

  private buildForm() {
    const fields: IUser = {
      full_name: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(6)]],
      repassword: ['']
    };

    this.form = this.formBuilder.group(fields, {
      validators: this.matchPasswords
    });

    this.f.email.setAsyncValidators(this.isUnique.bind(this));
  }

  public isProfile() {
    return this.id === this.authService.getLoginId();
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  public delete() {
    this.busyDelete();

    this.subscriptions.push(this.usersService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.usersService.purge();
          this.toastr.success('Utilizatorul a fost ștears!', 'Șters!');
          this.router.navigate(['utilizatori']);
        } else {
          this.toastr.error('Utilizatorul nu a fost stears!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this)));
  }

  private create(data: FormData) {
    this.busySave();

    return this.usersService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.usersService.purge();
          this.toastr.success('Utilizatorul a fost salvat!', 'Salvat!');
          this.router.navigate(['utilizatori', 'modifica', response.data.id]);
        } else {
          this.toastr.error('Utilizatorul nu a putut fi salvat!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();

    return this.usersService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.usersService.purge();
          this.toastr.success('Utilizatorul a fost actualizat!', 'Modificat!');
          this.router.navigate(['utilizatori', 'modifica', this.id]);

        } else {
          this.toastr.error('Utilizatorul nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private matchPasswords(group: FormGroup) {
    if (group.controls) {
      const password = group.controls.password.value;
      const passwordConfirmation = group.controls.repassword.value;

      return password === passwordConfirmation ? null : { noMatch: true };
    }

    return null;
  }

  private async isUnique(control: AbstractControl): Promise<any> {
    clearTimeout(this.debounceUniqueTimer);

    return new Promise((resolve, reject) => {
      this.debounceUniqueTimer = setTimeout(() => {
        const column = this.getControlName(control);
        const data = new FormData();
        data.append(column, control.value);

        this.usersService.unique(data, column, this.id)
          .subscribe((response: IResponse) => {
            if (response.success) {
              resolve(null);
            } else {
              resolve({ notUnique: true });
            }
          },
            (error) => {
              resolve({ notUnique: true });
            });
      }, 600);
    });
  }
}
