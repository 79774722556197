import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOrder } from 'src/app/interfaces/order.interface';
import { OrdersService } from '../services/orders.service';
import { Subscription } from 'rxjs';
import { OrderStatus } from 'src/app/configs/order-status';
import { OrderPaymentStatus } from 'src/app/configs/order-payment-status';
import { Base } from 'src/app/common/base.component';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent extends Base implements OnInit, OnDestroy {

  public orders: IOrder[] = [];

  private orderStatuses = OrderStatus;
  private paymentStatuses = OrderPaymentStatus;

  constructor(private ordersService: OrdersService) {
    super();
  }

  public zeroes(n) {
    return ('00000000' + n).slice(-6);
  }

  public orderStatus(s) {
    const status = this.orderStatuses.find((o) => o.ID.toString() === s.toString());
    if (status) {
      return status.LABEL;
    }
  }

  public paymentStatus(s) {
    const status = this.paymentStatuses.find((p) => p.ID.toString() === s.toString());
    if (status) {
      return status.LABEL;
    }
  }

  public total(products) {
    return 0;
  }

  public ngOnInit() {
    this.getOrders();
  }

  public ngOnDestroy() {
    this.orders = [];
    this.ordersService.empty();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public refresh() {
    this.busySync();
    this.ordersService.list(true);
  }

  public orderTableSort(collection, table, column) {
    this.sortColumn = column;
    this.direction = this.rotate[this.direction];
    collection = collection.sort((a, b) => {
      const res = this.compare(a[table][column], b[table][column]);
      return this.direction === 'asc' ? res : -res;
    });
  }

  private getOrders() {
    this.busy();

    this.subscriptions.push(
      this.ordersService.orders.subscribe((product) => {
        if (product !== null) {
          this.orders = product;
          this.idle();
        }
      }, this.onError.bind(this)));

    // always purge cache for orders. Use cache for view/edit only
    this.subscriptions.push(this.ordersService.list(true) as Subscription);
  }

}
