import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { article_categories } from 'src/app/interfaces/article-categories.interface';
import { HttpClient } from '@angular/common/http';
import { IResponse } from 'src/app/interfaces/response.interface';
import { Status } from 'src/app/configs/status';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticleCategoriesService {
  public articleCategoriesSubject: BehaviorSubject<article_categories[]>;
  public articleCategories: Observable<article_categories[]>;
  public deleteSubject: BehaviorSubject<String>;
  public cache = {
    articleCategories: null
  };
  constructor(private http: HttpClient) {
    this.articleCategoriesSubject = new BehaviorSubject<article_categories[]>(null);
    this.articleCategories = this.articleCategoriesSubject.asObservable();
    this.deleteSubject=new BehaviorSubject<String>(null);
    this.deleteSubject.subscribe((value)=>{console.log("aici in behaviour", value);
    if(value!=null){
    this.deletePicture(value).subscribe((value)=>{
      console.log("dupa delete");
    });}
  
  });
   }

   public read(id: number, purgeCache?: boolean) {

    if (!purgeCache && this.cache.articleCategories !== null) {
      const cache = this.cache.articleCategories.find(c => c.id_category === id);

      if (cache) {
        const r: IResponse = {
          success: true,
          status: Status.OK,
          data: cache
        };

        return of(r);
      }
    }

    return this.http.get(`${environment.apiUrl}/article_categories/read/${id}`);
  }

  public unique(data: FormData, column: string, id?) {
    if (id) {
      return this.http.post(`${environment.apiUrl}/article_categories/unique/update/${column}/${id}`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/article_categories/unique/insert/${column}`, data);
    }
  }

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/article_categories/create`, data);
  }

  public last_id(){
    return this.http.get(`${environment.apiUrl}/article_categories/get_last_id`);
  }

  public empty() {
    this.articleCategoriesSubject.next([]);

  }
  public list(purgeCache?: boolean) {

    if (!purgeCache && this.cache.articleCategories !== null) {
      this.articleCategoriesSubject.next(this.cache.articleCategories);
      return of(this.cache.articleCategories);
    }

    return this.http.get(`${environment.apiUrl}/article_categories/all`)
      .pipe(map((response: IResponse) => {
        this.cache.articleCategories = response.data;
        this.articleCategoriesSubject.next(response.data || []);
      })).subscribe();
  }

  public purge() {
    this.cache.articleCategories = null;
    this.empty();
    this.list(true);
  }
  public update(data: FormData, id: number) {
    return this.http.post(`${environment.apiUrl}/article_categories/update/${id}`, data);
  }

  public deletePicture(name: String){
    return this.http.delete(`${environment.apiUrl}/article_categories/delete_picture/${name}`);
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/article_categories/delete/${id}`);
  }

  public emit(msg: String){
    this.deleteSubject.next(msg);
  }
}
