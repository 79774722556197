import { CategoriesCharacteristicsService } from '../../services/categories-characteristics.service';
import { CharacteristicsFormComponent } from '../characteristics-form/characteristics-form.component';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ICharacteristic } from 'src/app/interfaces/characteristic.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Base } from 'src/app/common/base.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-characteristics-list',
  templateUrl: './characteristics-list.component.html'
})
export class CharacteristicsListComponent extends Base implements OnInit, OnDestroy {

  @Input() category;

  public characteristics: ICharacteristic[] = [];

  constructor(
    private modalService: NgbModal,
    private charService: CategoriesCharacteristicsService) {
    super();
  }

  public ngOnInit() {
    this.characteristics = [];
    this.getCharacteristics();
  }

  public ngOnDestroy() {
    this.characteristics = [];
    this.charService.empty();
    this.unsubscribe();
  }

  public newCharacteristic() {
    this.modal = this.modalService.open(CharacteristicsFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.category = this.category;

    this.checkRefresh(this.modal);
  }

  public editCharacteristic(characteristic) {
    this.modal = this.modalService.open(CharacteristicsFormComponent, {
      backdrop: 'static'
    });
    this.modal.componentInstance.edit(characteristic);

    this.checkRefresh(this.modal);
  }

  public refresh() {
    this.busySync();

    this.charService.list(this.category, true);
  }

  private getCharacteristics() {
    this.busy();

    this.subscriptions.push(this.charService.characteristics
      .subscribe((characteristics) => {
        if (characteristics !== null) {
          this.characteristics = characteristics;
          this.idle();
        }
      }, this.onError.bind(this)));

    this.subscriptions.push(this.charService.list(this.category) as Subscription);
  }

  private checkRefresh(modal) {
    modal.result.then((reason) => (reason === 'refresh') && this.refresh());
  }

}
