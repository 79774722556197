import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-is-busy',
  templateUrl: './is-busy.component.html',
  styleUrls: ['./is-busy.component.scss']
})
export class IsBusyComponent {

  @Input()
  text = 'Se încarcă ';

  constructor() { }

}
