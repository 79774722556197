import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Base } from 'src/app/common/base.component';
import { CategoriesService } from '../../categories/services/categories.service';
import { Component, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { IProduct } from 'src/app/interfaces/product.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { MainGroups } from 'src/app/configs/main-groups';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html'
})
export class ProductsFormComponent extends Base implements OnDestroy {

  public categories = [];

  constructor(
    private formBuilder: FormBuilder,
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    super();

    this.buildForm();

    // GET CATEGORY
    this.subscriptions.push(
      this.categoriesService.categories.subscribe((categories) => {
        if (categories != null) {
          this.categories = this.sort(categories, 'category_name_ro');
        }
      }, this.onError.bind(this)));

    this.categoriesService.list();

    // GET PRODUCT DATA
    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {

        // Same route no need to continue
        if (this.id === this.route.snapshot.params.id && this.route.snapshot.params.id) { return; }

        this.id = this.route.snapshot.params.id;

        // Edit mode
        if (this.id) {
          this.subscriptions.push(this.productsService
            .read(this.id)
            .subscribe((response: IResponse) => {

              if (!response.success) {
                this.toastr.error('Produsul nu există!', 'Eroare!');
                this.router.navigate(['404']);
                return;
              }

              this.setFormData(response.data);

              this.idle();
            }, this.onError.bind(this)));
        } else {
          this.idle();
        }
      }, this.onError.bind(this)));

  }

  public labelMainGroup(groupID) {
    const group = MainGroups.find(g => g.ID === groupID);
    return group ? group.LABEL : 'Toti';
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();
    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  public get f() {
    return this.form.controls;
  }

  private create(data: FormData) {
    this.busySave();

    return this.productsService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.productsService.purge();
          this.toastr.success('Produsult a fost salvat!', 'Salvat!');
          this.router.navigate(['produse', 'modifica', response.data.id]);
        } else {
          this.toastr.error('Produsult nu a putut fi salvat!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();

    return this.productsService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.productsService.purge();
          this.toastr.success('Produsult a fost actualizat!', 'Modificat!');
          this.router.navigate(['produse', 'modifica', this.id]);
        } else {
          this.toastr.error('Produsult nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  public delete() {
    this.busyDelete();
    this.subscriptions.push(this.productsService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.productsService.purge();
          this.toastr.success('Produsul a fost ștears!', 'Șters!');
          this.router.navigate(['produse']);
        } else {
          this.toastr.error('Produsul nu a fost ștears!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this)));
  }

  private buildForm() {

    const fields: IProduct = {
      composition_en: ['', Validators.required],
      composition_ro: ['', Validators.required],
      description_en: ['', Validators.required],
      description_ro: ['', Validators.required],
      id_category: ['', Validators.required],
      price: ['', Validators.required],
      sale_price: ['', Validators.required],
      product_code: ['', Validators.required],
      product_name_en: ['', Validators.required],
      product_name_ro: ['', Validators.required],
      seo_product_description_en: ['', Validators.required],
      seo_product_description_ro: ['', Validators.required],
      seo_product_title_en: ['', Validators.required],
      seo_product_title_ro: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);
  }
}
