import { Base } from 'src/app/common/base.component';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IColor, IColorSelectorConfig } from '@bytelabsco/ngx-color-selector';
import { IProductColor } from 'src/app/interfaces/product-color.interface';
import { IResponse } from 'src/app/interfaces/response.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsColorsService } from '../../services/products-colors.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-colors-form',
  templateUrl: './products-colors-form.component.html',
  styleUrls: ['./products-colors-form.component.scss']
})
export class ProductsColorsFormComponent extends Base implements OnDestroy {

  public idProduct: number;

  public currentColor: IColor = {
    hex: '#000'
  };

  public colorSelectorOptions: IColorSelectorConfig = {
    itemSize: {
      height: 24,
      width: 24
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private colorService: ProductsColorsService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    super();

    const fields: IProductColor = {
      color_ro: ['', Validators.required],
      color_en: ['', Validators.required]
    };

    this.form = this.formBuilder.group(fields);

    this.idle();
  }

  public edit(color: IProductColor) {
    this.id = color.id_product_color;
    this.idProduct = color.id_product;
    this.currentColor.hex = color.color_code;
    this.f.color_ro.setValue(color.color_ro);
    this.f.color_en.setValue(color.color_en);
  }

  public submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const data = this.getFormData();
    data.append('id_product', this.idProduct as any);
    data.append('color_code', this.currentColor.hex as any);

    this.subscriptions.push(this.isEdit() ? this.update(data) : this.create(data));
  }

  private create(data: FormData) {
    this.busySave();

    return this.colorService
      .create(data)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Culoarea a fost salvată!', 'Salvat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eroare nu a putut fi salvat!', 'Eroare!');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  private update(data) {
    this.busyUpdate();

    return this.colorService
      .update(data, this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Culoarea a fost actualizată!', 'Modificat!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Eraore nu a putut fi actualizat!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }

  public delete() {
    this.busyDelete();

    return this.colorService
      .delete(this.id)
      .subscribe((response: IResponse) => {
        if (response.success) {
          this.toastr.success('Culoarea a fost ștearsă!', 'Șters!');
          this.activeModal.close('refresh');
        } else {
          this.toastr.error('Culoarea nu a fost ștersă!', 'Eroare');
        }

        this.idle();
      }, this.onError.bind(this));
  }
}
